.actionBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 5em 0 1em;
  height: 100%;
  overflow: hidden;
}
.inputSearchContainer {
  position: relative;
  margin-left: 2%;
  margin-bottom: 10px;
  margin-top: 5px;
}
.interviewListBlock .css-1ex1afd-MuiTableCell-root {
  padding: -2px 5px !important;
  height: 92vh !important;
}
.inputSearchContainer > input {
  padding: 8px 5px;
  padding-left: 30px;
  border: 0.5px solid #161616;
  border-radius: 10px;
  width: 300px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.inputSearchContainer > input:focus {
  border: 1px solid #ccc;
}

.searchField:focus {
  outline-color: #161616;
}
