#adminDashboardBlock {
  overflow: hidden;
  width: 100%;
}

#adminDashboardBlock article {
  width: 100%;
  display: flex;
  height: 100vh;
  /* margin-top: 50px; */
}

#adminDashboardBlock > article > aside.sidebarBlock {
  position: fixed;
  margin-top: 50px;
  flex-basis: 15%;
  height: 100vh;
  width: 11%;
}

#adminDashboardBlock > article > aside.sidebarBlock ul {
  display: flex;
  flex-direction: column;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li {
  width: 100%;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li a {
  width: 100%;
  display: flex;
  border-bottom: 1px ridge gray;
  padding: 10px 5px;
  color: rgb(40 60 73);
  /* color: #fff; */
  text-decoration: none;
  font-size: 14px;
  gap: 14px;
  justify-content: center;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li a span:first-child {
  position: relative;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li span:last-child {
  flex-basis: 90%;
  margin: 0 14px;
}

#adminDashboardBlock
  > article
  > aside.sidebarBlock
  ul
  li
  a:first-child
  span
  svg {
  color: #3081c8;
  font-weight: bold;
  font-size: 25px;
  position: absolute;
  left: 0px;
  top: -4px;
}

#adminDashboardBlock > article > aside.mainadminBlock {
  margin-top: 50px;
  /* margin-left: 11%; */
  flex-basis: 100%;
  height: 100vh;
  width: 100%;
}

#adminDashboardBlock article ul li a:hover {
  /* background: #246c98 !important; */
  background-color: rgb(211, 211, 211);
  transition: ease all 0.7s;
}

#adminDashboardBlock article table tr td {
  position: relative;
}

#adminDashboardBlock article table tr tr td a {
  color: green;
  font-size: 27px;
  position: absolute;
  top: 0;
}

#BatchGridLayout article {
  display: flex;
  /* background: #efefef; */
  padding: 10px;
  gap: 10px;
  flex-wrap: wrap;
  height: 85vh;
  overflow-y: auto;
}

#BatchGridLayout article .container {
  border-radius: 0 10px;
  padding: 10px;
  box-shadow: 0 0 1px 4px #1a53761a;
  width: 270px;
  height: 30vh;
  background-color: #fff;
}

@media (min-width: 350px) and (max-width: 500px) {
  #BatchGridLayout article .container {
    width: 280px;
    height: 30vh;
  }
}

@media (min-width: 1090px) and (max-width: 1200px) {
  #BatchGridLayout article .container {
    width: 300px;
    height: 30vh;
  }
}

@media (min-width: 1201px) and (max-width: 1405px) {
  #BatchGridLayout article .container {
    width: 340px;
    height: 30vh;
  }
}

@media (min-width: 1406px) and (max-width: 2000px) {
  #BatchGridLayout article .container {
    width: 300px;
    height: 30vh;
  }
}

#BatchGridLayout article .container h1 {
  font-size: 15px;
  padding: 0px 0 5px;
  color: #fa7826;
  font-weight: bold;
  border-bottom: 2px solid #1a5276;
  margin: 5px 0 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 1px 5px 0px 2px;
  line-height: 20px;
  text-transform: capitalize;
}

.container p {
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  gap: 10px;
  margin: 10px 0;
  text-transform: capitalize;
  color: #e5e7eb;
  align-items: center;
}

.container p span {
  color: #1a1d24;
  display: flex;
  gap: 5px;
}

.container a {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 12px;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid #f16f1d;
  transition: ease all 0.7s;
  /* background: #10b981; */
  background: #f16f1d;
}

.container a:hover {
  background: #f06712;
  border: 1px solid #f06712;
  color: #fff;
  text-decoration: none;
}

.container a span {
  margin: 1px 5px 0 0px;
  display: inline-block;
}

.chatMenuWrapper {
  position: relative;
  height: 75vh;
}

.ConversationBlock {
  overflow-y: scroll;
  height: 92vh;
}

.Search {
  display: flex;
  background: #fff;
  border: 1px solid #4b5563;
  border-radius: 15px;
  padding: 3px 5px;
  margin: 5px 15px 0px;
  width: 250px;
  box-shadow: 0 0 1px 3px #ff61241f;
  border: 1px solid #f16f1d;
}

@media (min-width: 200px) and (max-width: 400px) {
  .Search {
    /* flex-basis: 20.3%;
      height: 20vh; */
    width: 200px;
  }
}

@media (min-width: 200px) and (max-width: 700px) {
  .SideBarName {
    display: none;
  }
}

@media (min-width: 701px) and (max-width: 1000px) {
  .SideBarName {
    display: none;
  }
}

@media (min-width: 1001px) and (max-width: 1100px) {
  .SideBarName {
    font-size: 12px;
  }
}

@media (min-width: 200px) and (max-width: 1000px) {
  .SideBarIcon {
    padding: 5px;
    border-bottom: 2px solid #111;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #adminDashboardBlock
    > article
    > aside.sidebarBlock
    ul
    li
    a:first-child
    span
    svg {
    position: static !important;
    left: 100px;
  }

  #adminDashboardBlock > article > aside.sidebarBlock ul li a span:first-child {
    position: static !important;
  }
}

@media (min-width: 200px) and (max-width: 700px) {
  .SideBarIcon > svg {
    font-size: 30px;
    position: static;
  }
}

.SideBarName {
  font-size: 14px;
}

.Search input {
  width: 100%;
  padding: 4px 3px;
  border: none;
  background: rgba(102, 62, 183, 0);
  outline: none;
}

.Search span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.AddQuestionsBlock form {
  display: flex;
  flex-direction: column;
  gap: 1%;
}

.AddQuestionsBlock form div {
  margin: 2px;
}

/* add Interview form css */
.AddQuestionsBlock1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.inputBlock {
  width: 80%;
  /* width: 60%; */
}

.inputBlock header {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  /* position: absolute;
  right: 0; */
}

.inputBlock header span {
  position: absolute;
  right: 0;
}

.inputBlock header input {
  padding: 5px;
}

.inputBlock footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
}

.inputBlock footer input {
  padding: 5px;
}

.selectRound {
  width: 80%;
}

.ModalReq {
  /* border: 1px solid black !important; */
  /* box-shadow: 2px 2px 2px 2px; */
}

.ReqHeader {
  padding: 0px 5px;
  border-bottom: 2px solid darkgray;
}

.ModalHead {
  color: #f16f1d;
}

.AddReqBtn {
  background-color: #f16f1d;
  color: #fff;
}

.ReqHeader span {
  color: #000 !important;
  font-size: 23px !important;
  font-weight: bolder !important;
}

.ReqListView {
  white-space: normal;
  word-wrap: break-word;
  padding: 0px 8px;
}

.ReqListView1 > h5 {
  border-bottom: 0.1px ridge rgba(216, 216, 216, 0.556);
}

.ReqListView1 > h5:last-child {
  border-bottom: none;
}

.ReqListView h5 {
  color: #f16f1d;
}

.ReqListView h5 > b {
  width: 35%;
  display: inline-block;
  color: #ff7c2a;
  margin-right: 2%;
  font-size: medium;
}

.ReqListView h5 > b > small {
  color: #ff7c2a;
  font-size: small;
}

.datareq {
  font-size: 15px;
  color: #666;
}

.detailsRow {
  margin-bottom: 10px;
}

.ReqListView h5 > span {
  font-size: 15px;
  color: #666;
  display: inline-block;
  width: 60%;
}

.MessageError {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  flex-direction: column;
}

.MessageError > img {
  height: 300px;
}

.MessageError > h3 {
  color: #f16f1d;
}

.MessageError1 {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  flex-direction: column;
}

.MessageError1 > img {
  height: 180px;
}

.MessageError1 > h3 {
  color: #f16f1d;
  font-size: x-large;
}

.HistoryRating ul {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  margin: 5px;
  gap: 5px;
  list-style: none;
  /* border: 1px solid black; */
}

.ratinggreen {
  color: #10b981 !important;
  margin: 1px 2px !important;
  padding: 5px !important;
  /* color: #fff !important; */
  font-weight: 600 !important;
  border-bottom: 1px solid !important;
}

.ratinggreen:hover {
  color: #10b981 !important;
  padding: 5px !important;
}

.ratingyellow {
  color: orange !important;
  margin: 1px 2px !important;
  padding: 5px !important;
  /* color: #fff !important; */
  font-weight: 600 !important;
  border-bottom: 1px solid !important;
}

.ratingyellow:hover {
  color: orange !important;
  padding: 5px !important;
}

.ratingred {
  color: red !important;
  margin: 1px 2px !important;
  padding: 5px !important;
  /* color: #fff !important; */
  font-weight: 600 !important;
  border-bottom: 1px solid !important;
}

.ratingred:hover {
  color: red !important;
}

/* profile css starts here */
#profilepage {
  width: 80%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  /* background-color: #4b5563; */
}

.profileheader {
  width: 90%;
  height: 80vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 20px 2px;
  gap: 2px;
  margin: 10px;
  /* margin-bottom: 20px; */
}

.profileheaderleft {
  flex-basis: 50%;
  border-right: 1px dashed gray;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageBlock {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: 3px;
  border: 10px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profilename {
  font-size: 24px;
  margin: 0;
}

.profileheaderright {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 20px;
}

.profileheaderright aside .NumberBlock {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.ProfileName {
  width: 100%;
  background-color: #38bff8e6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
}

.DeleteAccount {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.ReightTop {
  width: 92%;
  height: 10%;
}

.ReightBottomBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.ReightBottom {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ReightBottom h5 {
  width: 100%;
  padding: 0px 20px;
  /* background-color: yellow; */
}

.heading {
  color: #b45309;
  display: inline-block;
  width: 25%;
}

.heading1 {
  color: #b45309;
  display: inline-block;
  width: 25%;
  /* margin-left: 30px; */
}

.Value {
  color: #404040;
  display: inline-block;
  width: 70%;
}

.Valuenumber {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s, max-height 0.5s ease-in-out;
}

.active {
  opacity: 1;
  max-height: 1000px;
  /* Adjust as needed */
}

.Valueemail {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s, max-height 0.5s ease-in-out;
}

.activeemail {
  opacity: 1;
  max-height: 1000px;
  /* Adjust as needed */
}

/* profile css ends here */
.reqdeatils {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.AddButton {
  font-size: 30px;
  color: green;
}

.deleteicon {
  font-size: 20px;
  color: red;
}

.Bluebtn {
  color: #fff !important;
  background-color: #2563eb !important;
}

.Bluebtn:hover {
  background-color: #1e40af !important;
}

.greenbtn {
  background-color: #16a34a !important;
  color: #fff !important;
}

.greenbtn:hover {
  background-color: #15803d !important;
}

.inputBlock {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;
  width: 100%;
}

.inputBlock header {
  display: flex;
  margin-bottom: 5px;
}

.inputBlock header label {
  margin-right: 10px;
  padding: 3px;
}

.inputBlock header input[type="text"] {
  width: 100%;
  padding: 5px;
  border-radius: 8px;
  border: 1px soild blue;
  background: #f2f2f2;
  outline: none;
  border: 1px solid lightgray;
  background: #fff;
}

.inputBlock header input[type="text"]:focus {
  outline: none;
  border-color: #5c9cea;
}

.inputBlock header .deleteicon {
  cursor: pointer;
}

.inputBlock header .deleteicon > svg {
  font-weight: bold;
  font-size: 20px;

  color: red;
  border: 2px solid gray;
  border-radius: 10px;
}

.inputBlock footer label {
  margin-right: 10px;
}

.inputBlock footer textarea {
  width: 100%;
  height: 50px;
  padding: 5px;
  border-radius: 8px;
  border: 1px soild blue;
  background: #f2f2f2;
  outline: none;
  border: 1px solid lightgray;
  background: #fff;
  /* background: #f2f2f2; */
}

.inputBlock footer textarea:focus {
  outline: none;
  border-color: #5c9cea;
}

.AddButton {
  display: inline-block;
  cursor: pointer;
  margin-top: 10px;
  color: #5c9cea;
}

.AddButton:hover {
  color: #337ab7;
}

.Comment {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Comment label {
  font-weight: bold;
  font-size: 16px;
}

.Comment > textarea {
  padding: 5px;
  border-radius: 8px;
  border: 1px soild blue;
  background: #f2f2f2;
  outline: none;
  border: 1px solid lightgray;
  background: #fff;
  /* background-color: #f2f2f2; */
}

.inputBlock > aside > label {
  font-weight: bold;
  font-size: 16px;
}

.HeadingAdd {
  display: flex !important;
  justify-content: center !important;
  font-size: 26px !important;
  color: #f16f1d !important;
  font-weight: bold !important;
}

.MainProfileBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 92vh;
}

.MainProfileBlock > button {
  padding: "10px 20px" !important;
  background: "blue" !important;
  color: "white" !important;
  border-radius: "5px" !important;
  border: "none" !important;
  cursor: "pointer" !important;
}

.AddReq {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddReq button {
  font-size: 15px;
  font-weight: bold;
  background-color: #16a34a;
}

.AddReq button:hover {
  background-color: #137e3a;
}

#reactpaginate {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px;
  /* background: #efefef; */
}

#reactpaginate ul {
  display: inline-block;
  margin-left: 20px;
  padding-left: 0;
}

#reactpaginate li {
  display: inline-block;
  border: 1px solid #1ebadf;
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
}

#reactpaginate li a {
  padding: 2px 5px;
  display: inline-block;
  color: #000;
  outline: none;
  text-decoration: none;
}

#reactpaginate li:hover {
  background: #1ebadf !important;
}

#reactpaginate li a:hover {
  background: #1ebadf !important;
}

#reactpaginate li.active {
  background: rgb(92, 198, 240);
  outline: none;
}

.Green {
  background-color: #10b98160 !important;
}

.redColor {
  background-color: rgb(168, 4, 4) !important;
  color: #fff !important;
  margin: 5px !important;
}

/* Assuming your Styles object is used for CSS modules */
/* You may need to adjust class names according to your project setup */

/* Style for the form container */
.fileuploadcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #ccc;
}

/* Style for the heading */
.fileuploadheading {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Style for the file input */
.file-input {
  display: none;
  /* Hide the default file input */
}

/* Style for the custom file upload button */
.fileuploadbutton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

/* Style for the submit button */
/* .submitbutton {
  background-color: #28a745 !important;
  color: #fff !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
} */

/* Style for the file upload and submit button container */
.buttoncontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.buttoncontainer button {
  background-color: #28a745 !important;
  color: #fff !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

/* Style for the form elements */
.formelement {
  margin-bottom: 15px;
}

.SearchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.searchIcon {
  position: relative;
  padding: 14px 15px;
  background-color: #f16f1d;
  border: none;
  color: #fff;
  font-size: medium;
  cursor: pointer;
  border-radius: 5px;
}

.searchIcon svg {
  /* position: absolute;
  right: 8px;
  top: 11px;
  color: #666; */
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-position: center;
}

.searchCancel {
  position: relative;
  padding: 8px 8px;
  background-color: rgb(216, 11, 11);
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.searchCancel svg {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.AddQ {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ModalNumber {
  display: flex;
  justify-content: space-around;
}

.chatBoxTop {
  /* overflow-y: auto; */
  /* position: relative; */
  /* height: inherit; */
  height: 81vh;
}

.HeaderHelp {
  display: flex;
}

#RequirementBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 91vh;
}
#RequirementBlock1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 91vh;
}

.card {
  width: 300px;
  height: 230px;
  border: 1px solid #ddd;
  border-radius: 0 10px;
  overflow: hidden;
  margin: 16px;
  box-shadow: 0 0 1px 4px #1a53761a;
  background: #fff;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
}

.cardgroom {
  width: 300px;
  height: 298px;
  border: 1px solid #ddd;
  border-radius: 0 10px;
  overflow: hidden;
  margin: 16px;
  box-shadow: 0 0 1px 4px #1a53761a;
  background: #fff;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
}

.card1 {
  height: 395px;
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 0 10px;
  overflow: hidden;
  margin: 16px;
  box-shadow: 0 0 1px 4px #1a53761a;
  background: #fff;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
}

.cardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.cardContent {
  padding: 10px 0px 0px 10px;
  display: flex;
}

.cardContent1 {
  padding: 7px 0px 0px 10px;
  display: flex;
}

.cardTitle {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid;
}

.CardHeading > h4 {
  color: #e9500e;
}

.cardDescription {
  color: #555;
  font-size: 14px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.buttonContainer1 {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.button1 {
  padding: 4px 10px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }
}

.button2 {
  padding: 4px 10px;
  font-size: 14px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #218838;
  }
}
.button3 {
  padding: 4px 10px;
  font-size: 14px;
  /* background-color: #98a728; */
  /* color: #fff; */
  border: 2px solid;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #1675e0;
    color: #fff;
  }
}
.Colan {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 2px;
}

.RequireTable {
  width: 100%;
  height: 82vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.NoDataBlock {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 80vh;
}

.Heading {
  font-size: 20px;
}

@media (max-width: 500px) {
  .MessageError > img {
    height: 150px;
  }

  .MessageError > h3 {
    text-align: center;
    font-size: medium;
  }

  .MessageError1 > img {
    height: 150px;
  }

  .MessageError1 > h3 {
    text-align: center;
    font-size: medium;
  }
}
