#chatBoxSection article {
  width: 100%;
  background-color: #efefef;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
}

.chattype {
  flex-basis: 15%;
  height: 90vh;
  background: #cccccc36;
  padding: 10px;
}
.chatMenu {
  flex-basis: 25%;
  flex-basis: 25%;
  height: 92vh;
  background: #fff;
  padding: 10px;
}
.chatBox {
  flex-basis: 75%;
  height: 90vh;
  overflow-y: scroll;
}

.chatMenu h1 {
  font-size: 14px;
  text-transform: capitalize;
  padding: 0px 0;
  color: #f16f1d;
  text-shadow: 0px 0px 0px #f16f1d;
  line-height: 0px;
}

.chatMenu input {
  padding: 7px;
  border: none;
  width: 100%;
  border-radius: 5px;
  margin: 6px 0 6px;
  background: transparent;
  border: 1px solid #f16f1d;
  outline: none;
  font-size: 13px;
  text-transform: capitalize;
}

.chatMenuWrapper {
  position: relative;
  height: 75vh;
}
.chatMenuWrapper1 {
  position: relative;
  /* height: 75vh; */
}
.ConversationBlock {
  overflow-y: scroll;
  height: 100%;
}
.ConversationBlock1 {
  /* overflow-y: scroll;
  height: 96%; */
}
.searchIcon svg {
  position: absolute;
  right: 8px;
  top: 16px;
  color: #666;
}
.listUser {
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea8;
  border-radius: 5px;
  margin: 3px 0;
  /* text-transform: capitalize; */
}
.listUser1 {
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea8;
  border-radius: 5px;
  margin: 3px 0;
  /* text-transform: capitalize; */
}
.listUser:hover {
  background: #adacaca8;
}
.conversation {
  color: rgb(40 60 73);
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.chatBoxBottom {
  /* box-shadow: 0 0 30px rgba(127,137,161,.3);
    position: fixed;
    bottom: 6px;
    width: 62%;
    right: 47%;
    display: flex;
    background: #fff;
    padding: 10px;
    z-index: 1;
    left: 36.6%; */
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.3);
  position: fixed;
  bottom: 6px;
  width: 75%;
  /* right: 47%; */
  display: flex;
  background: #fff;
  padding: 10px;
  z-index: 1;
}

.chatBox {
  position: relative;
}
.noConversationText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 90vh;
}

.chatBoxBottom > input {
  color: #1f2937;
  background: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  width: 99%;
  outline: 1px solid #ccc;
  /* outline: 1px solid greenyellow; */
}

.chatBoxBottom > input::placeholder {
  color: #64748b;
}
.chatBoxBottom button {
  /* width: auto; */
  border: none;
  padding: 0px 22px;
  background: #f16f1d;
  color: #fff;
  display: inline-block;
  border-radius: 6px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid #f16f1d;
  cursor: pointer;
  outline: none;
  height: 50px;
  width: 80px;
}
.message.own {
  align-items: flex-end;
}

.sendermsg {
  width: 80%;
  margin: 3px 7px;
  padding: 0px 5px;
  float: right;
  /* background-color: #fff;
  border-radius: 12px;
  
  border-bottom: 1px solid rgb(103 58 183 / 33%);
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  flex-direction: column; */
}
.sendermsg > main {
  background-color: #fff;
  padding: 4px 18px;
  padding-left: 12px;
  border-radius: 0px 16px 16px 16px;
  /* margin: 0px 30px; */
  float: right;
  border-bottom: 1px solid rgb(103 58 183 / 33%);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

.recivermsg {
  width: 80%;
  margin: 0px 15px;
  float: left;
  padding: 0px 20px;
}

.recivermsg > main {
  background-color: #14b8a6;
  padding: 4px 18px 4px 12px;
  border-radius: 12px 0px 16px 16px;
  margin: 4px 0px;
  float: left;
  border-bottom: 1px solid rgb(103 58 183 / 19%);
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
.recivermsg .download {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.recivermsg .download > a {
  color: #fff;
}
.chatBoxTop {
  overflow-y: hidden;
  position: relative;
  padding-right: 10px;
  margin-bottom: 50px;
}
.active {
  background-color: #666;
}

.sendermsg time {
  font-size: 10px;
  color: #888;
}

.recivermsg time {
  color: #efefef;
  font-size: 10px;
}
.sendermsg div:nth-child(2) {
  /* float: right;
  text-align: right; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.symbolbroadcast {
  float: right;
  text-align: right;
}
.recivermsg div:nth-child(2) {
  /* float: right;
  text-align: right; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatBoxTop div p {
  white-space: pre-wrap;
  word-break: break-word;
  /* word-break: break-all; */
}

.chatBoxTop > main {
  background: #fff;
  padding: 7px;
  font-size: 15px;
  text-transform: capitalize;
  color: #111;
  border-top: 1px solid #cccccc1f;
  border-bottom: 1px solid #ccc;
  position: fixed;
  width: 100%;
  /* position: fixed; */
  /* position: fixed;
    top: 0%;
  width: 100%; */
}
.chatBoxTop > main div {
  align-items: center;
  display: flex;
  width: 62%;
  justify-content: space-between;
}
.chatBoxTop > main div span {
  font-size: 14px;
  color: #666;
}
.chatBoxTop > main h2 {
  line-height: 18px;
  font-size: 20px;
}
.recivermsg > main div p a {
  /* color: #fff; */
  color: #3498ff;
}
#UserBlock h2 {
  display: flex;
  gap: 5px;
  font-weight: 300;
}

.faIcon {
  font-size: 15px;
  color: green;
  padding: 3px 2px;
  display: inline-block;
}

#online {
  color: green;
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
}
#offline {
  color: red;
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
}
.Username {
  font-size: 17px !important;
  font-weight: bold;
  line-height: 6px;
}
.customStylesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffffad;
  width: 40%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UploadfileContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 20px;
  color: #475569;
}
.UploadfileContainer span {
  font-size: 120%;
  /* padding: 10px; */
  background: transparent;
  color: black;
}

.Messageeee:nth-child(1),
.Messageeee:nth-child(1) {
  margin-top: 40px;
}

/* Modal css starts here */

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 250px;
  height: 170px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

/* .modalContent {
  margin: 15px auto;
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
} */

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.Broadcast {
  padding: 2px 9px;
  background: #f16f1d;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: bold;
}

.Broadcast:hover {
  background-color: #fb6101;
}

.Head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Emptymsg {
  display: none;
}
.Messageeee {
  padding: 0px;
}

.displaynone {
  display: none;
}
.lastmsg {
  font-size: 11px;
  color: #666c66;
  display: flex;
  justify-content: space-between;
}
.Nolastmsg {
  font-size: 11px;
  color: #666c66;
  display: flex;
  justify-content: space-between;
}
.notify {
  background: rgba(7, 192, 7, 0.877);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* color: chartreuse; */
}
.notify span {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
.attachfile {
  font-size: 20px;
}
.noConversation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 75vh;
}
.Download {
  color: #3498ff;
  display: block;
  text-decoration: underline;
}
.sendermsg > main > div .Document {
  color: black;
  text-decoration: none;
}
.sendermsg > main > div .Document:hover {
  text-decoration: none;
  color: black;
}
.recivermsg > main div .Document {
  color: white;
  text-decoration: none;
}
.recivermsg > main > div .Document:hover {
  text-decoration: none;
  color: white;
}
.messageTop pre {
  /* font-family: Lato, sans-serif;
  font-size: 14px;
  color: #fff;
  /* background-color: rgba(143, 142, 142, 0.12); */
  /* padding: 5px;  */
  white-space: pre-wrap; /* Ensure long text wraps */
  word-wrap: break-word;
}


.namerole {
  color: #888;
}
.messageBottom {
  /* gap: 6px; */
  width: 100%;
  /* display: flex; */
  /* justify-content: space-between;; */
}
.time {
  margin-right: 10px;
  display: flex;
  gap: 7px;
}
.displayNone {
  display: none;
}
.Searchhh > input {
  padding: 3px;
  border-radius: 5px;
}

.btndownload {
  background: none;
  color: #3498ff;
  display: block;
  text-decoration: underline;
}
.btndownload:hover {
  color: #2563eb;
}
.btndownload1 {
  background: none;
}
.Download:hover {
  color: #2563eb;
}
.ForwardMsgBlock div button {
  padding: 5px 5px !important;
  color: #34c3ff;
}
.AudioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AudioBlock > div > main {
  font-size: 18px;
  padding-right: 5px;
}
.StartAudio {
  color: black;
}
.StopAudio {
  color: red;
}
.SendAudioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PlayAudio {
  color: #16a34a;
}
.DeleteAudio {
  color: red;
  font-size: 22px;
  padding: 0px 8px 0px 4px;
}
.SendAudio {
  padding: 5px 16px;
  border: 2px solid green;
  border-radius: 5px;
  color: #fff;
  background: green;
  font-size: 17px;
  text-transform: capitalize;
}
.recordingAnimation {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.recordingAnimation.active {
  opacity: 1;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.AudioBlock > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ropdowncontent {
  position: absolute;
  top: -100px;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 100px;
}
.ropdowncontent > ul {
  text-decoration: none;
  list-style: none;
}
.FileShare:hover {
  background-color: #efefef;
  border-radius: 5px;
}
.HeaderPoll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #f16f1d;
}
.HeaderPoll > span {
  margin-right: 20px;
}
.modalcontent {
  margin: 15px auto;
  padding: 20px;
  /* border: 1px solid #888; */
  width: 100%;
  max-width: 600px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background: #efefef;
  /* text-align: center;  */
}
.InputQue {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-bottom: 2px solid gray;
}
.InputQue:focus {
  border-bottom: 2px solid #4caf50;
}

.AddOptions {
  background-color: lightgray;
  color: #666;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 100;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SubmitPoll {
  background-color: transparent;
  color: #f16f1d;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 40px;
}
.OptionBlock {
  display: flex;
  flex-direction: column;
}
.ClearOption {
  background: transparent;

  padding-left: 10px;
}
.Allow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.datePicker {
  margin: 10px 0;
}
.Pollhead {
  display: flex;
  justify-content: flex-start;
  color: #fb6101;
  text-transform: capitalize;
  font-weight: bold;
}
.AnswerPoll {
  padding: 5px 8px;
  border-radius: 5px;
  background: #34c3ff;
  color: #fff;
  font-weight: bold;
}
.AnswerPoll:hover {
  background: #05b0f9;
}
.AnswerView {
  background-color: #7e57c2;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 4px #0003;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  margin: 2px;
  padding: 4px 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
.AnswerView:hover {
  background-color: darken(#7e57c2, 10%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
}
.NotySort {
  cursor: pointer;
  font-size: 20px;
  color: #16a34a;
}
.ViewPollBlock {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  max-height: 550px;
}
.MainContainer {
  width: 500px;
}
.options {
  padding: 5px;
  background-color: #efefef;
  border-radius: 5px;
}
.Voters {
  display: flex;
  justify-content: space-between;
}
/* Style for the container holding the checkbox and label */
.polloption {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

/* Style for the checkbox input */
.polloption > input[type="checkbox"] {
  margin-right: 10px;
}

/* Style for the label associated with the checkbox */
.polloption > label {
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  background: #efefef;
  padding: 5px;
  border-radius: 6px;
}

/* Style for the label when hovering */
.polloption > label:hover {
  text-decoration: underline;
}
.TextAlign {
  white-space: pre-wrap;
  word-break: break-word;
}
.deleteicon {
  font-size: 20px;
  color: red;
}
.QuestionBlock {
  display: flex;
  justify-content: space-between;
}
.deleteicon > svg {
  border: 2px solid red;
  border-radius: 50%;
}
.errormessage {
  color: red;
}
.InputOption {
  display: flex;
  width: 100%;
}
.AddPoll {
  font-size: 20px;
  color: #16a34a;
}
.AddPoll > svg {
  border: 2px solid #16a34a;
  border-radius: 50%;
}
.SpinnerBlock {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.textEditStyle {
  line-height: normal;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  word-break: break-word;
}
.textEditStyle > p {
  margin-top: 0px;
}
.responsiveChip {
  position: fixed;
  bottom: 78px;
}
