#authBlock {
  overflow: hidden;
}
#authBlock article {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83vh;
}

#authBlock > article > div {
  width: 25%;
}

@media (max-width: 1440px) {
  #authBlock > article > div {
    width: 40%;
  }
}

.AddIcon {
  position: absolute;
  top: 3px;
  margin-left: 2%;
}
/* position: absolute;
top: 7px;
margin-left: 38%; */
#authBlock form {
  /* background: #fff; */
  background: #2c3e50;
  padding: 20px;
  margin: 20px 0;
  width: 100%;
}
#authBlock h1 {
  text-align: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: 20px;
  color: #fff;
  line-height: 22px;
}

#authBlock form label {
  display: block;
  padding: 5px 0;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
}

#authBlock form input {
  display: block;
  padding: 6px;
  font-weight: 500;
  border: 1px solid #eee;
  outline: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 2px;
}

#authBlock form button {
  width: 100%;
  margin: 15px 0;
  border: none;
  padding: 8px 30px;
  background: #3b82f6;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid #3b82f6;
  cursor: pointer;
}

#authBlock form button:hover {
  border: 1px solid #2563eb;
  background-color: #2563eb;
  color: white;
}

/* CreateBatch Css */
#CreateBatch {
  width: 100%;
}
#CreateBatch article {
  width: 100%;
}
.container {
  margin-left: 20px !important;
  margin-right: 20px !important;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: auto;
  box-shadow: 0px 1px 5px 1px gray;
}
.formgroup {
  /* background: #000; */
  width: 30%;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}
/* .formgroup1{ */
/* background: #000; */
/* width: 40%; */
/* margin: 0px 20px; */
/* width: 100%; */

/* } */
.container h1 {
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 24px;
  text-decoration: underline;
}
/* .createBatchForm { */
/* width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2%;
  flex-wrap: wrap;
  justify-content: space-evenly; */
/* background: #ccc; */
/* } */
.createBatchForm > div {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.trainer {
  width: 30%;
  position: relative;
}
.trainer1 {
  width: 30%;
  position: relative;
}
.trainer1 > div {
  width: 100%;
}
.trainer12 {
  width: 40%;
}

.createBatchForm div label {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

.createBatchForm div input {
  /* width: 100%;
  padding: 8px 5px;
  border-radius: 5px;
  border: none;
  border-bottom: 1px solid; */
  width: 100%;
  padding: 10px 5px;
  border-radius: 5px;
  border: none;
  border: 1px solid #adacacd6;
  /* margin: 7px 0;
  /* margin: 7px 0;  */
}
.createBatchForm div input:focus {
  outline: 2px solid #007aff;
  border: none;
}
/* .RegisterBlock{
  width: 100%;
} */
.RegisterBlock1 {
  width: 100% !important;
}

/* .createBatchForm div select {
  padding: 8px 5px;
  border-radius: 5px;
  border: none;
  border-bottom: 1px solid;
  background: #fff;
  border: 1px solid  #403f3f;
  margin: 7px 0;
} */
.createButton {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px;
  gap: 5px;
}
.createButton button {
  padding: 5px 13px;
  font-weight: bold;
  border-radius: 3px;
  font-size: 16px;
  border: 1px solid #3b82f6;
  color: rgb(103, 58, 183);
  background: #3b82f6;
  color: #fff;
  position: relative;
  top: 1px;
  cursor: pointer;
}
.createButton button:hover {
  border: 1px solid #2563eb;
  background: #2563eb;
  color: #fff;
}
.SelectOption option {
  height: 85%;
  overflow-y: scroll;
}

.pass aside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye {
  padding: 6px;
  border: 1px solid #eee;
  color: #e5e7eb;
}
/* .Option{
  width: 250px;
  background: #000;
 } */
.option {
  width: 250px;
}

.inputforBranch {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
}
.inputforBranch div form {
  display: flex;
  gap: 2px;
}
.inputforBranch aside button {
  top: -14px;
  position: absolute;
  right: -14px;
}

.inputforBranch div button {
  padding: 8px 10px;
  background: #2c3e50;
  color: #fff;
  font-weight: bold;
}

.inputforBranch div input {
  padding: 8px 10px;
  border-radius: 5px;
}
.errors {
  color: red;
  font-size: 12px;
  padding: 0px 4px;
}
/* .gapbtw{ */
/* margin-bottom: 15px; */
/* } */
.errrr {
  display: none;
}
.AddStudents {
  width: 94%;
}
.Qrcode {
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
