#brandLandingPage {
  height: 100vh;
  padding: 53px 2px 2px 0px;
  background-color: #fff;
  display: flex;
}

#brandLandingPage > article {
  height: 100%;
  width: 17%;
  padding: 5px;
}

#brandLandingPage > main {
  height: 100%;
  width: 83%;
  padding: 5px;
  background-color: #e5e7e9;
}

.sideBarMenus {
}

.selectedMenu {
  background: #f16f1d;
  color: #fff !important;
  border-radius: 0 15px 15px 0;
}

.sideBarMenus > ul {
  list-style: none;
  margin-top: 30px;
}

.sideBarMenus > ul > li {
  padding: 6px;
  font-size: medium;
}

.sideBarMenus > ul a {
  text-decoration: none;
  width: 100%;
  color: inherit;
  display: inline-block;
}

.formContainerFields > div > div {
  background-color: #fff;
}

/* ===================================== */
/* ChatMessage.module.css */
.chatContainer {
  height: 100%;
  overflow: auto;
  transition: transform 0.5s ease-in-out;
}

.chatCard {
  margin: 10px 2%;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  /* width: 50%; */
  min-width: 50%;
  max-width: 70%;
  /* width: auto; */
  overflow: auto;
}

.chatCard:hover {
  /* transform: translateY(-2px); */
}

.chatImage {
  width: 100%;
  max-width: 400px;
  height: auto;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
}

.chatImage:hover {
  opacity: 0.9;
}

.chatText {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
  color: black;
}

.senderId {
  font-size: 14px;
  color: #777;
  text-transform: capitalize;
}

.roleBtnContainer {
  display: flex;
  width: 100%;
  gap: 10px;
  font-weight: bold;
  align-items: center;
}

.roleBtnContainer > button,
.viewReqBtn {
  border: 0.3px solid inherit;
  cursor: pointer;
  border-radius: 7px;
  padding: 2px 5px;
  color: #fff;
  color: inherit;
  font-weight: 600;
}

.videoContainer {
  position: relative;
  max-width: fit-content;
}

.videoContainer > button {
  position: absolute;
  background-color: #fff;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid #f16f1d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreFilterBtn {
  float: right;
  cursor: pointer;
  color: green;
  padding: 2px 4px;
  border-radius: 7px;
  font-weight: bold;
  border: 2px solid green;
}

.fileupload {
  display: flex;
  width: 100%;
}

.fileupload input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fileupload {
  margin-bottom: 10px;
}

.ViewChatIcon {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ViewChatIcon > div > svg {
  font-size: x-large;
}
.spideyHead {
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  background: lightgray;
}
.spideyHeading {
  color: rgb(241, 111, 29);
  width: 50%;
  text-align: end;
}
.filterBlock {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: end;
}
.filterLine {
  color: gray;
  width: 15%;
}
@media (min-width: 501px) and (max-width: 700px) {
  .spideyHead {
    height: 5%;
  }
  .spideyHeading {
    text-align: start;
    width: 20%;
  }
  .filterBlock {
    width: 80%;
  }
}
@media (min-width: 360px) and (max-width: 500px) {
  .spideyHead {
    height: 10%;
  }
  .spideyHeading {
    text-align: start;
    width: 30%;
    font-size: 16px;
  }
  .filterLine {
    font-size: 16px;
    width: 25%;
  }
  .filterBlock {
    width: 70%;
  }
}
@media (max-width: 359px) {
  .spideyHead {
    height: 15%;
  }
  .spideyHeading {
    text-align: start;
    width: 30%;
    font-size: 16px;
  }
  .filterLine {
    font-size: 16px;
    width: 35%;
  }
  .filterBlock {
    width: 70%;
  }
}
