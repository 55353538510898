/* src/components/LoginForm.module.css */

.authBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  min-height: 92vh;
}

.container {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
}
.formBlock {
  width: 45%;
  display: flex;
  justify-content: center;
}
/* Responsive width adjustments for smaller screens */
@media (max-width: 768px) {
  .formBlock {
    width: 70%;
    /* padding: 1.5rem; */
  }
}

@media (max-width: 480px) {
  .formBlock {
    width: 100%;
    /* padding: 1rem;
     */
  }
}
.heading {
  color: rgb(241, 111, 29);
  margin-bottom: 1.5rem;
  font-weight: 500 !important;
}

.inputGroup {
  margin-bottom: 1.5rem;
  text-align: left;
}

.inputWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #666;
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
  margin: 0.5rem 0 0;
}

.submitButton {
  background-color: rgb(241, 111, 29);
  color: #fff;
  text-transform: none;
  margin-top: 1.5rem;
}

.submitButton:hover {
  background-color: #c55915;
}

.footerBlock {
  padding: 1rem;
  text-align: center;
  background-color: #333;
  color: #fff;
}

.footerBlock h3 {
  margin: 0.5rem;
}

.footerBlock .icon {
  margin-right: 0.5rem;
}
.footerContent {
  display: flex;
  justify-content: space-between;
}
.labelHeading {
  font-weight: bold;
  font-size: larger;
}
