@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

#authBlock article {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 83vh;
  z-index: 1000;
  width: 100%;
}

#authBlock1 article {
  display: flex;

  align-items: center;
  justify-content: center;
  height: 69vh;
}

.btns {
  background-color: transparent;
  border: none;
  z-index: 999;
}

@media (max-width: 1400px) {
  #authBlock > article > div {
    width: 50%;
    height: inherit;
  }
}

@media (max-width: 1440px) {
  #authBlock > article .container {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.RegisterBlock {
  width: 40% !important;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

#RegisterBlock1 {
  width: 100% !important;
  background: rgb(40 60 73);
}

#authBlock1 h1 {
  color: #fff;
}

#authBlock1 form {
  background: rgb(40 60 73);
  padding: 0px 20px;
  margin: 0px 0;
  width: 100%;
}

#authBlock form {
  /* background: #fff; */
  background: #2c3e50;
  padding: 20px;
  margin: 5px 0;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 2px 0;
}

/* #authBlock form div label{
 
} */
#authBlock1 h1 {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 20px 20px 20px;
  line-height: 0px;
}

#authBlock h1 {
  text-align: center;
  font-size: 1.6rem;
  /* text-transform: uppercase; */
  font-weight: bold;
  margin: 20px;
  color: #fff;
  line-height: 22px;
}

#authBlock h2 {
  text-align: center;
  font-size: 26px;
  text-transform: capitalize;
  font-weight: bold;
  margin: 20px;
  line-height: 20px;
  margin-bottom: 0px;
  color: #f44336;
}

#authBlock1 h4 {
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  /* font-weight: bold; */
}

#authBlock h4 {
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  /* font-weight: bold; */
}

#authBlock1 form label {
  display: block;
  padding: 5px 0px 0px;
  font-weight: bold;
  text-transform: capitalize;

  font-size: 15px;
  color: #fff;
  /* line-height: 5px; */
}

#authBlock form label {
  display: block;
  padding: 5px 0;
  font-weight: bold;
  text-transform: capitalize;
  color: rgb(11, 11, 11);
  font-size: 20px;
}

.closeModal1 {
  position: absolute;
  top: 0;
  right: 0;
}

#authBlock1 form input {
  display: block;
  padding: 6px;
  font-weight: 100;
  border: 1px solid #eee;
  outline: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 3px;
  font-size: 14px;
  line-height: 0px;
}

#authBlock form input {
  display: block;
  padding: 10px;
  font-weight: 100;
  border: 1px solid rgb(23, 22, 22);
  outline: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 5px;
  /* outline: 2px solid #007aff; */
  font-size: 16px;
  outline: none;
}

#authBlock form input:focus {
  border: 1px solid rgb(238, 238, 238);
}

#authBlock1 form button {
  width: 100%;
  margin: 15px 0;
  border: none;
  padding: 8px 30px;
  background: rgb(103, 58, 183);
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid rgb(103, 58, 183);
  cursor: pointer;
}

#authBlock form button {
  width: 100%;
  margin: 15px 0;
  border: none;
  padding: 8px 30px;
  background: #3b82f6;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid #3b82f6;
  cursor: pointer;
  /* outline: 2px solid #007aff; */
}

#authBlock1 form button:hover {
  border: 1px solid rgb(140, 83, 240);
  background-color: rgb(140, 83, 240);
  color: white;
}

#authBlock form button:hover {
  border: 1px solid #2563eb;
  background-color: #2563eb;
  /* color: rgb(40 60 73); */
  color: #fff;
}

#textdanger {
  color: #ef476f;
  padding: 0px 5px;
}

.pass aside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye {
  padding: 6px;
  border: 1px solid #eee;
  color: #e5e7eb;
}

.Selectoption select {
  display: block;
  padding: 6px;
  font-weight: 100;
  border: 1px solid #eee;
  outline: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 3px;
  background: #fff;
}

.branch {
  display: block;
  padding: 6px;
  font-weight: 100;
  border: 1px solid #eee;
  outline: none;
  margin: 5px 0;
  width: 100%;
  border-radius: 3px;
  background: #fff;
}

.addEmail {
  position: relative;
}

.removeemail {
  display: none;
}

.addEmail1 {
  display: none;
}

.removeemail1 {
  display: block;
  position: relative;
}

.removeemail1 span {
  position: absolute;
  right: 5px;
  bottom: 8px;
  font-size: 18px;
  color: rgb(15, 15, 15);
}

.removeee {
  position: relative;
}

.removeee span {
  position: absolute;
  right: 5px;
  bottom: 4px;
  font-size: 23px;
  color: rgb(46, 46, 46);
}

.removeeenum {
  position: relative;
}

.removeeenum span {
  position: absolute;
  right: 5px;
  bottom: 4px;
  font-size: 23px;
  color: rgb(46, 46, 46);
}

.addEmail span {
  position: absolute;
  right: 5px;
  bottom: 8px;
  font-size: 15px;
  color: rgb(46, 46, 46);
}

.addEmail span {
  position: absolute;
  right: 5px;
  bottom: 8px;
  font-size: 15px;
  color: gray;
}

.CloseModal1 {
  position: relative;
}

.CloseModal1 button {
  position: absolute;
  top: 3px;
  right: 2px;
}

#authBlock1 {
  width: 270px;
}

.errors {
  color: red;
  font-size: 12px;
  padding: 0px 4px;
}

.errrr {
  color: red;
}

/* @media (min-width: 200px) and (max-width: 400px) {
  #authBlock > article .container {
    width: 60%;
  }
}
@media (min-width: 200px) and (max-width: 400px) {
  #authBlock > article .container {
    width: 60%;
  }
} */
.Displaynone {
  display: none;
}

@media (min-width: 200px) and (max-width: 500px) {
  #authBlock > article .container {
    width: 100%;
  }
}

.forgot a {
  color: yellow;
}

.forgot a:hover {
  color: #fbbf24;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNotFound {
  width: 100%;
  height: 91vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

.reasons {
  position: relative;
}

.AddIcon {
  position: absolute;
  top: 7px;
  margin-left: 5%;
  color: #fff;
}

.AddIconBlock {
  position: absolute;
  top: 7px;
  margin-left: 5%;
  color: black;
}

.errors {
  color: red;
  font-size: 12px;
  padding: 0px 4px;
}

/* .gapbtw{ */
/* margin-bottom: 15px; */
/* } */
.errrr {
  display: none;
}

.AddReasonBlock {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.AddReasonBlock input {
  width: 50%;
  padding: 8px 16px;
  background: darkslategray;
  color: white;
  border-radius: 5px;
}

.AddReasonBlock input::placeholder {
  color: white;
}

.AddReasonBlock button {
  display: inline-block;
  background-color: green;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.AddReasonBlock button:hover {
  background-color: darkgreen;
}

.reasons1 {
  width: 60%;
}

.dropdownforreason {
  width: 60%;
}

.ListOfUsers {
  font-family: Arial, sans-serif;
  padding: 20px;
  border: 1px solid #ccc;
  /* Add a border around the entire list */
  border-radius: 4px;
}

h1 {
  font-size: 24px;
  margin-bottom: 5px;
}

.UserSection {
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.Username {
  font-weight: bold;
  color: #333;
}

.formgroupBranch {
  height: 100px;
  overflow-y: auto;
}

.SwitchUsers {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  margin: 23px auto;
  position: relative;
  box-shadow: 0 0 20px 9px #ff61241f;
  border-radius: 30px;
}

.SwitchStudent {
  background-color: transparent !important;
  /* color: #000 !important; */
  padding: 10px 30px !important;
  /* margin: 10px !important; */
  color: black;
  font-weight: bold;
  cursor: pointer !important;
  /* transition: background-color 0.3s ease-in-out !important; */
  border: 0;
  outline: none;
  position: relative;
}

#btn {
  top: 0;
  left: 0;
  position: absolute;
  width: 110px;
  height: 100%;
  background: linear-gradient(to right, #ff102f, #ffad06);
  border-radius: 30px;
  transition: 0.5s;
}

/* Login Form */
#authBlock {
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 91vh;
}

#authBlock form {
  background-color: #fff;
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  padding: 20px;
  /* width: 400px; */
  text-align: center;
}

#authBlock h1 {
  margin-bottom: 20px;
  color: #f16f1d;
  margin-bottom: 24px;
  text-align: center;
  font-size: 26px;
}

#authBlock .pass {
  margin-bottom: 15px;
  position: relative;
}

#authBlock label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

#authBlock input[type="text"],
#authBlock input[type="email"],
#authBlock input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid rgb(23, 22, 22);
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

#authBlock .eye {
  position: absolute;
  right: 10px;
  top: 73%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: gray;
}
#authBlock .eye > svg {
  font-size: 16px;
}

#authBlock .errors {
  color: red;
  text-align: left;
  margin-top: 5px;
  font-size: 14px;
}

#authBlock .forgot {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
}

#authBlock a {
  text-decoration: none;
  color: #007bff;
}

#authBlock button[type="submit"] {
  /* background-color: #007bff; */
  background: linear-gradient(to right, #ff102f, #ffad06);
  color: black;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

#authBlock button[type="submit"]:hover {
  background: linear-gradient(to right, #ffad06, #ff102f);
  color: #fff;
  border: none;
}

#authBlock button[type="submit"]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#authBlock .loader {
  display: inline-block;
  margin-right: 10px;
}

/* Footer */
.FooterBlock {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.FooterBlock article {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FooterBlock h3 {
  margin-left: 10px;
}

.FooterBlock .icons {
  font-size: 24px;
  margin-right: 5px;
}

/* Responsive adjustments */
@media screen and (max-width: 480px) {
  #Styles.authBlock form {
    width: 90%;
  }
}

.coloredimage {
  filter: grayscale(100%) !important;
  /* Convert the image to grayscale */
  mix-blend-mode: color !important;
  background-color: #f16f1d !important;
  /* Set the desired color */
}

.MainLoginBlock {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 8px;
  width: 500px;
  text-align: center;
}

@media (min-width: 200px) and (max-width: 500px) {
  .MainLoginBlock {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: -webkit-fill-available;
    text-align: center;
  }
}

.ImageBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Slab", serif;
  /* color: #f16f1d; */
}

.ImageBlock > h1 {
  color: #e9500e !important;
}

.Loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Updatecontainer {
  height: 90vh;
  overflow-y: auto;
}
