#navbarSection {
  background-color: #fff;
  height: 50px;
  width: 100%;
  box-shadow: 1px 0px 4px 0px #cccccc5e;
  position: fixed;
  z-index: 111;
}

#navbarSection > article {
  background-color: #fff;
  height: inherit;
  width: 98%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navbarSection .menuBlock ul {
  list-style: none;
  display: flex;
  align-items: center;
}

#ProfileIcon {
  text-decoration: none;
  padding: 4px 13px;
  margin: 4px;
  font-weight: bold;
  border-radius: 3px;
  font-size: 14px;
}

.logoBlock h1 {
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 1.2px;

  font-size: 24px;
  color: #f16f1d;
  font-weight: bolder;
}

.logoBlock h1 span {
  font-size: 16px;
}

#navbarSection .menuBlock ul {
  margin-bottom: 0;
}

#navbarSection .menuBlock ul li button,
.Logoutbtn {
  text-transform: capitalize;
  padding: 5px 13px;
  font-weight: bold;
  border-radius: 3px;
  font-size: 14px;
  background: linear-gradient(to right, #ff102f, #ffad06);
  color: #fff;
  position: relative;
  top: 1px;
  cursor: pointer;
}

#navbarSection .menuBlock ul li button:hover,
.Logoutbtn:hover {
  background: linear-gradient(to right, #ffad06, #ff102f);
  color: #fff;
}

.NavBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.Profile svg {
  font-size: 30px;
}

.Help {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  position: relative;
}

.Noti {
  width: 10px;
  height: 10px;
  background: green;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 11px;
}

.HelpIcon {
  color: #f16f1d;
  display: flex;
  position: relative;
}

.HelpIcon:hover {
  color: #f15b1a;
}

.dropdownContent {
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  background: #fff;

  border-radius: 5px;
}

.DropdownList {
  padding: 5px;

  border-bottom: 1px solid;
}

.DropdownList:hover {
  background-color: lightgray;
}

.DropdownList1 {
  padding: 5px;
}

.DropdownList1:hover {
  background-color: lightgray;
}
.AddQueBtn {
  color: "rgb(241, 111, 29)";
  border-bottom: "2px solid ";
  margin-right: 20px;
}
.AddQueBtn:hover {
  background-color: lightgray;
  color: #f3520d;
}

/* Student Navbar  */
.StudentNavBlock {
  display: flex;
}
.HomeBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-left: 20px;
}
.StudHome {
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  margin: 0;
  color: #333;
  font-weight: 800;
  font-size: 15px;
  transition: color 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
}
.StudHome:hover {
  color: rgb(241, 111, 29); /* Change text color on hover */
  background-color: rgba(241, 111, 29, 0.1); /* Subtle background color */
  transform: scale(1.1); /* Zoom effect */
  border-radius: 4px; /* Rounded corners */
}
/* .StudHome:hover {
  color: #0056b3; 
  background-color: #e0e7ff;
  border-radius: 4px; 
} */
@media (max-width: 800px) {
  .StudHome {
    font-size: 12px;
  }
  .HomeBlock {
    margin-left: 10px;
  }
}

.StudHome:hover {
  color: #333;
}
/* .StudHome > div:hover {
  color: #f15b1a;
} */

#preview {
  height: 4vh;
  width: 4vh;
  object-fit: contain;
  border-radius: 50%;
}

/*  new css student side mobile view  */
#headerBlock {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 10vh;
  background-color: #2677b0;
}

#headerBlock nav {
  width: 90%;
  height: inherit;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 10%;
}

#headerBlock nav figure {
  flex-basis: 20%;
}

#headerBlock nav figure a {
  color: white;
  text-decoration: none;
  font-size: 3.5vh;
  font-weight: bold;
}

#headerBlock nav ul {
  float: right;
  width: 100%;
  height: inherit;
  flex-basis: 70%;
  list-style: none;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-end;
}

#headerBlock nav ul li a {
  color: #fff;
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: vh;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

#headerBlock nav ul li a:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

#headerBlock nav ul li div {
  color: #fff;
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: vh;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

#headerBlock nav ul li div:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

#preview {
  height: 4vh;
  object-fit: contain;
  border-radius: 50%;
}

/* for Admin Header */
#adminHeaderBlock {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 10vh;
  /* background-color:#D6DBDF; */
  background-color: #2d2d2d;
}

#adminHeaderBlock nav {
  width: 90%;
  height: inherit;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 10%;
}

#adminHeaderBlock nav figure {
  flex-basis: 20%;
}

#adminHeaderBlock nav figure a {
  color: #f8931f;
  text-decoration: none;
  font-size: 3vh;
  font-weight: bold;
}

#adminHeaderBlock nav ul {
  float: right;
  width: 100%;
  height: inherit;
  flex-basis: 70%;
  list-style: none;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-end;
}

#adminHeaderBlock nav ul li a {
  color: #f8931f;
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: 2vh;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

#adminHeaderBlock nav ul li a:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

/* For Student Header */
#studentHeaderBlock {
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 10vh; */
  /* background-color:#D6DBDF; */
  background-color: #092c40;
}

#studentHeaderBlock nav {
  width: 97%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  gap: 10%;
}

#studentHeaderBlock nav figure {
  flex-basis: 10%;
}

#studentHeaderBlock nav figure a {
  color: #dfdfdf;
  text-decoration: none;
  /* font-size: 3.5vh; */
  font-weight: bold;
}

#studentHeaderBlock nav ul {
  float: right;
  width: 100%;
  height: inherit;
  flex-basis: 40%;
  list-style: none;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-end;
}

#studentHeaderBlock nav ul li a {
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;

  /* transition: 0.3s all ease-in-out; */
}

#navLinksStudents li a {
  color: #3c3838;
}

#navLinksStudentsM li a {
  color: #dfdfdf;
}

#navLinksStudents li div {
  color: #222;
}

#navLinksStudentsM li div {
  color: #dfdfdf;
}

#studentHeaderBlock nav ul li a:hover {
  /* transform: scale(1.01); */
  /* transition: 0.3s all ease-in-out; */
  /* font-weight: 700; */
}

#studentHeaderBlock nav ul li div {
  /* color: #F96900; */
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: 2vh;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  font-weight: 600;
}

#studentHeaderBlock nav ul li div:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

@media (max-width: 700px) {
  #headerBlock {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 8vh;
    background-color: #2677b0;
  }

  #headerBlock nav figure a {
    font-size: 3vh;
  }

  #headerBlock nav ul li a {
    font-size: 1.8vh;
  }

  #studentHeaderBlock {
    height: 8vh;
  }

  #studentHeaderBlock nav figure a {
    font-size: 3vh;
  }

  #studentHeaderBlock nav ul li a {
    font-size: 1.6vh;
  }

  #preview {
    /* height: 25px; */
  }

  #adminHeaderBlock {
    height: 8vh;
  }

  #adminHeaderBlock nav figure a {
    font-size: 3vh;
  }

  #adminHeaderBlock nav ul li a {
    font-size: 1.8vh;
  }
}

.popupNav {
  border: none !important;
  color: #575757;
}

.conatinerName {
  width: 100%;
  height: 62px;
  padding: 16px;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  border-radius: 12px;
  display: flex;
  line-height: 24px;
  letter-spacing: 0.5px;
  transition: background-color 0.3s ease, font-weight 0.3s ease;
  text-decoration: none;
  color: inherit;
  list-style: none;
}

.conatinerName:hover {
  background-color: #fdf2e6;
  font-weight: 600;
}
