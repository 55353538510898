#adminDashboardBlock article {
  /* background: #efefef; */
  display: flex;
  min-height: 92vh;
  justify-content: center;
  /* align-items: center; */
  /* overflow-y: auto; */
}

#adminDashboardBlock > article > aside.sidebarBlock {
  /* margin-top: 50px; */
  flex-basis: 12%;
  background-color: #fff;
  border-top: 1px solid #efefef;
  width: 11%;
  position: fixed;
  left: 0;
  height: 92vh;
  top: 50px;
  overflow: auto;
}

#adminDashboardBlock > article > aside.sidebarBlock ul {
  display: flex;
  flex-direction: column;
}

#adminDashboardBlock > article > aside.sidebarBlock ul a > a {
  color: #000;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li {
  /* display: flex;
  align-items: center; */
  width: 100%;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li a {
  background: #eeeeee26;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #efefef;
  padding: 10px 5px;
  color: rgb(40 60 73);
  text-decoration: none;
  font-size: 14px;
  gap: 8 px;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li a span:first-child {
  flex-basis: 10%;
  position: relative;
}

/* Right side */
#adminDashboardBlock > article > aside.sidebarBlock ul li span:last-child {
  flex-basis: 90%;
  /* margin: 0 14px; */
}

#adminDashboardBlock
  > article
  > aside.sidebarBlock
  ul
  li
  a:first-child
  span
  svg {
  color: #3081c8;
  font-weight: bold;
  width: 20px;
  height: 20px;
  /* position: absolute;
  left: 0px;
  top: -4px; */
}

#adminDashboardBlock > article > aside.mainadminBlock {
  margin-top: 50px;
  flex-basis: 90%;
  background-color: #fff;
  /* background: #2c3e50; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 11%;
  overflow: hidden;
  height: 92vh;
}

#adminDashboardBlock article ul li a:hover {
  background: #eee !important;
  transition: ease all 0.7s;
  /* border-bottom: rgb(131, 15, 15)  !important; */
  border-bottom-color: rgb(0 0 0 / 46%) !important;
}

#adminDashboardBlock article table tr td {
  position: relative;
  color: black;
  flex: 1;
  /* border-right: 1px solid grey; */
}

#adminDashboardBlock article .designtable tr td {
  position: relative;
  color: black;
  padding: 5px;
}

#adminDashboardBlock article table tr tr td a {
  color: green;
  font-size: 27px;
  position: absolute;
  top: 0;
}

#textdanger {
  color: red;
  padding: 0px 5px;
}

/* pagination starts here */
.Pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PaginationUL {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 20px;
}

.PaginationUL li {
  list-style: none;
  /* padding: 5px 10px; */
  border: 1px solid rgb(100, 197, 221);
  cursor: pointer;
  text-overflow: ellipsis;
}

/* .PaginationUL li a{
  text-decoration: none;
  color: black;
  text-overflow: ellipsis;

} */
.pageno {
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  /* text-overflow: ellipsis; */
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.symbol {
  font-size: 15px;
}

.pagecount {
  font-size: 12px;
}

.anchordisable {
  pointer-events: none;
  color: gray;
  padding: 5px, 10px;
}

.anchordisablenext {
  pointer-events: none;
  color: gray;
  padding: 5px, 10px;
}

.anchor {
  padding: 5px, 10px;
}

/* .PaginationUL li:hover{
  background:  rgb(100, 197, 221);
  color: white;

}  */
/* .PaginationUL li a:hover{
  background:  rgb(100, 197, 221);
  color: white;
}  */

/* Close Reopen for admin starts here */
.close {
  /* padding: 4px 8px; */
  background: #fff;
  border: none;
  border-radius: 4px;
  color: rgba(233, 12, 12, 0.678);
  text-transform: capitalize;
  font-weight: bold;
  /* border: 1px solid rgba(233, 12, 12, 0.678); */
}

.close svg {
  font-size: 22px;
}

.close:hover {
  color: #fff;
  background: rgba(204, 58, 58, 0.678);
}

.closeborder {
  font-size: 14px;
  background: rgba(233, 12, 12, 0.678);
  border: none;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
  font-weight: bold;
  padding: 2px 4px;
  border: 1px solid rgba(233, 12, 12, 0.678);
}

.closeborder:hover {
  color: #fff;
  background: #dc2626;
  border: 1px solid #dc2626;
}

.reopen {
  font-size: 14px;
  padding: 2px 5px;
  /* background: rgba(12, 233, 12, 0.596); */
  background: #2dd4bf;
  border: none;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid #2dd4bf;
}

.reopen:hover {
  color: #fff;
  background: #14b8a6;
  border: 1px solid #14b8a6;
}

.BatchClosed {
  background-color: #f5353540 !important;
}

.GroupClosed {
  background-color: #f5353540 !important;
}

tr,
td {
  border: 1px solid #efefef;
  font-size: 14px;
}

.SearchBar {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  /* background: rgba(255, 217, 0, 0.295); */
}

.SearchBar > div {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: space-between;
  align-items: center;
}

.SearchBar div h1 {
  font-size: 25px;
  padding: 2px 15px;
  line-height: 0px;
  color: #f16f1d;
}

.sssss {
  /* width: 100%; */
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 8px 5px;
  /* background: rgba(255, 217, 0, 0.295); */
  /* flex-basis: 70%; */
}

.heading h1 {
  width: 60%;
  font-size: 25px;
  padding: 2px 15px;
  line-height: 0px;
  color: #f16f1d;
}

.SearchBar span {
  position: relative;
}

.SearchBar span input {
  padding: 7px;
  border: none;
  border-radius: 5px;
  margin: 6px 0 10px;
  background: #efefef5c;
  border: 1px solid #efefef63;
  outline: rgb(53, 51, 51);
  font-size: 12px;
}

.searchIcon svg {
  position: absolute;
  right: 12px;
  top: 12px;
  color: #666;
}

.pagination > li {
  list-style: none;
  border: 0.3px solid;
}

.pagination > li > a,
.pagination > li > span {
  float: left;
  padding: 8px 20px;
  line-height: 1.5;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.pagination > li > a:hover {
  background-color: #218838;
  color: white;
  cursor: pointer;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
}

/* a:active {
  color: red;
} */

#Emptymsg {
  /* color: gray; */
  background: red;
  /* display: none; */
}

.Search {
  width: 30%;
  display: flex;
  background: transparent;
  border: 1px solid #4b5563;
  border-radius: 15px;
  padding: 3px 5px;
  margin-right: 10px;
}

.Search span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.Search input {
  width: 100%;
  padding: 4px 3px;
  border: none;
  background: rgba(102, 62, 183, 0);
  outline: none;
}

.errormsg {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.errormsg h1 {
  color: rgb(40, 60, 73);
  font-size: 1.8rem;
}

#reactpaginate {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 10px;
  /* background: #efefef; */
}

#reactpaginate ul {
  display: inline-block;
  margin-left: 20px;
  padding-left: 0;
}

#reactpaginate li {
  display: inline-block;
  border: 1px solid #1ebadf;
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  margin-bottom: 5px;
}

#reactpaginate li a {
  padding: 2px 5px;
  display: inline-block;
  color: #000;
  outline: none;
  text-decoration: none;
}

#reactpaginate li:hover {
  background: #1ebadf !important;
}

#reactpaginate li a:hover {
  background: #1ebadf !important;
}

#reactpaginate li.active {
  background: rgb(92, 198, 240);
  outline: none;
}

.Block a {
  color: red;
  font-size: 16px;
  padding: 0px 20px;
  width: 5%;
}

.Block a:hover {
  color: darkred;
}

.Block1 a {
  color: red;
  font-size: 16px;
  padding: 0px 20px;
  width: 100px;
}

.Block1 a:hover {
  color: darkred;
}

.Block1 {
  width: 100px;
}

.UnBlock a {
  color: #0d9488;
  padding: 0px 20px;
  font-size: 20px;
  width: 5%;
}

.UnBlock a:hover {
  color: #14b8a6;
}

.UnBlock1 {
  width: 100px;
}

.UnBlock1 a {
  color: #0d9488;
  padding: 0px 20px;
  font-size: 20px;
}

.UnBlock1 a:hover {
  color: #14b8a6;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.permanentBlock {
  background: rgba(233, 12, 12, 0.678);
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  /* border: red; */
  border: 1px solid rgba(233, 12, 12, 0.678);
  /* width: 70px; */
}

.permanentBlock:hover {
  background: #dc2626;
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  /* border: red; */
  border: 1px solid #dc2626;
}

.permanentBlock1 {
  background: rgba(233, 12, 12, 0.678);
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  /* border: red; */
  border: 1px solid rgba(233, 12, 12, 0.678);
  width: 100px;
}

.permanentBlock1:hover {
  background: #dc2626;
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  /* border: red; */
  border: 1px solid #dc2626;
}

.permanentUnBlock {
  background: #2dd4bf;
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  /* border: red; */
  border: 1px solid #2dd4bf;
}

.permanentUnBlock:hover {
  background: #14b8a6;
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  /* border: red; */
  border: 1px solid #14b8a6;
}

.QandqBatchList {
  width: 100%;
  height: 92vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
}

.Inputhitapi {
  padding: 5px;
  margin: 5px;
  border-radius: 15px;
}

.QRCODE {
  width: 13%;
  height: 26px;
  background: transparent;
}

.QRCODE svg {
  font-size: 28px;
}

.searchIcon {
  position: relative;
  padding: 16px 16px;
  background-color: #10b981;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.searchIcon svg {
  /* position: absolute;
  right: 8px;
  top: 11px;
  color: #666; */
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-position: center;
}

.searchCancel {
  position: relative;
  padding: 8px 8px;
  background-color: rgb(216, 11, 11);
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.searchCancel svg {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SearchText {
  flex-basis: 85%;
}

.tableFixHead {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  height: 81vh;
  border: none;
  /* border: 1px solid #aaa6a6; */
  font-size: 13px;
  /* overflow-x: auto; */
}

.tableFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 74vh;
}

.tableFixHead thead tr {
  padding: 0;
  width: 100%;
  display: flex;
}

.tableFixHead tbody tr {
  padding: 0;
  width: 100%;
  display: flex;
}

/* .tableFixHead th,
.tableFixHead  td {
  padding: 5px 10px;
  width: 200px;
} */
th {
  border: none;
  background: rgb(40, 60, 73);
  flex: 1 !important;
  /* color: rgb(40, 60, 73); */
}

.TableA {
  padding: 5px 10px;

  width: 14.3%;
}

.TableB {
  padding: 5px 10px;
  width: 7.15%;
}

.TableC {
  padding: 5px 10px;
  width: 28.6%;
}

.Tableidra {
  padding: 5px 10px;

  width: 6.25%;
}

.Tableidq {
  padding: 5px 10px;

  width: 12.5%;
}

.Tableida {
  padding: 5px 10px;

  width: 18.75%;
}

.Tableidee {
  padding: 5px 10px;

  width: 25%;
}

.Tableid {
  padding: 5px 10px;

  width: 10%;
}

.Tableidg {
  padding: 5px 10px;

  width: 11.111%;
}

.Tableidr {
  padding: 5px 10px;

  width: 5%;
}

.Tablenamer {
  padding: 5px 10px;

  width: 15%;
}

.Tableemailr {
  padding: 5px 10px;

  width: 20%;
}

.Tablename {
  padding: 5px 10px;
  /* width: 200px; */
  width: 10%;
}

.Tableemail {
  padding: 5px 10px;
  /* width: 300px; */
  width: 10%;
}

.Tableid1 {
  padding: 5px 10px;
  /* width: 80px; */
  width: 10%;
}

.Tableemail1 {
  padding: 5px 10px;
  /* width: 350px; */
  width: 10%;
}

.Tablenumber {
  padding: 5px 10px;
  /* width: 100px; */
  width: 10%;
}

.Tablenumber1 {
  padding: 5px 10px;
  /* width: 150px; */
  width: 10%;
}

.Tableblock1 {
  padding: 5px 10px;
  /* width: 100px; */
  width: 10%;
}

.Tableunblock1 {
  padding: 5px 10px;
  /* width: 100px; */
  width: 10%;
}

.Tablepermanebt1 {
  padding: 5px 10px;
  /* width: 100px; */
  width: 10%;
}

.Tablerole {
  padding: 5px 10px;
  /* width: 10px; */
  width: 10%;
}

.Tablebranch {
  padding: 5px 10px;
  /* width: 150px; */
  width: 10%;
}

.Tablebatches {
  padding: 5px 10px;
  /* width: 70px; */
  width: 10%;
}

.Tablegroup {
  padding: 5px 10px;
  /* width: 70px; */
  width: 10%;
}

.Tableupdate {
  padding: 5px 10px;
  /* width: 70px; */
  width: 10%;
}

.Tableupdate1 {
  padding: 5px 10px;
  /* width: 100px; */
  width: 10%;
}

.Tableblock {
  padding: 5px 10px;
  /* width: 65px; */
  width: 10%;
}

.Tableunblock {
  padding: 5px 10px;
  /* width: 65px; */
  width: 10%;
}

.Tablepermanebt {
  padding: 5px 10px;
  /* width: 90px; */
  width: 10%;
}

@media (min-width: 1500px) and (max-width: 1600px) {
  .Tablebranch {
    padding: 5px 10px;
    /* width: 350px; */
    width: 10%;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .Tablebranch {
    padding: 5px 10px;
    /* width: 350px; */
    width: 10%;
  }
}
