.StatusBlock {
  /* display: flex;
  justify-content: space-between; */
  background-color: #f5f5f5;
  /* padding: 20px; */
}

.StatusBlock > article {
  width: 100%;
  display: flex;
}

.UserStatusBlock {
  width: 35%;
  height: 92vh;
  /* margin-right: 20px; */
  border-right: 1px solid #555;
  /* padding-right: 20px; */
  background: #fff;
  overflow-y: auto;
  border: 1px solid lightgray;
}

.ListUser {
  margin-bottom: 10px;
  color: #333;
  /* font-weight: bold; */
  /* font-size: 20px; */
  cursor: pointer;
  padding: 6px 11px;
  border-bottom: 1px solid #f7f7fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border-radius: 5px; */
  margin: 3px 0;
  width: 100%;
  background-color: #d8d5d5a8;
}

.ListUser:hover {
  border-radius: 5px;
  margin: 3px 0;
  width: 100%;
  /* background: #eeeeee4b; */
  background: #c5c3c3;
  /* Light gray background on hover */
}

.ListUser1 {
  margin-bottom: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid #f7f7fa;
  display: flex;
  justify-content: space-between;
  /* border-radius: 5px; */
  margin: 3px 0;
  width: 100%;
  background: #f16f1d;
}

.ListUser1:hover {
  border-radius: 5px;
  margin: 3px 0;
  width: 100%;
  /* background: #f0f0f0; Light gray background on hover */
}

.MyStatusBlock {
  width: 65%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #1f2e36; */
  overflow-y: auto;
}

.MyStatusBlock h2,
.UserStatusBlock h2 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

.MyStatus {
  /* width: 100px;
    height: 100px;
    background-color: #128C7E;
    border-radius: 50%; */
  border-radius: 8px;
  /* background: #333; */
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  width: inherit;
  position: relative;
}

.MyStatus > div > div {
  display: flex;
}

.imagecontainer {
  height: 168px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;

  @media (min-width: 200px) {
    height: 192px;
  }
}

img {
  /* width: 100%;
    height: 100%; */
  object-fit: cover;
}

.content {
  padding: 24px 16px 31px;
}

.heading {
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 80%; */
}

.heading .StatusMsg {
  font-size: 20px;
  height: 90%;
}

.headingtitle {
  font-weight: 400;
  font-size: 28px;
  padding-bottom: 10px;
  line-height: 34px;
}

.headingsubtitle {
  font-weight: 600;
  font-size: 25px;
  color: #4b4c53;
  padding-bottom: 19px;
}

.detailstext {
  font-weight: 200;
  line-height: 26px;
  color: #4b4c53;
  font-size: 15px;
  padding-bottom: 31px;
  word-spacing: 1px;
}

.detailsbtn {
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  word-spacing: -4px;
  color: #6267a1;
}

.Carousel {
  position: relative;
}

.Carousel .carousel .slide {
  text-align: center !important;
}

.Carousel .legend {
  font-size: 16px !important;
  color: #555 !important;
}

.Carousel .carousel-slider {
  margin-bottom: 20px !important;
  display: flex;
}

.customPrevArrow,
.customNextArrow {
  background-color: #dbdcdd !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  /* padding: 10px 20px !important; */
  cursor: pointer !important;
  transition: background-color 0.2s ease-in-out !important;
  font-size: xx-large;
}

.customPrevArrow:hover,
.customNextArrow:hover {
  /* background-color: #0056b3; */
}

.addstatusBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addstatusBlock .text {
  font-size: 20px;
}

/* .addstatusBlock .icon{
    font-size: 40px;
  }
  .addstatusBlock .icon > svg > circle:hover{
  fill: green;
  } */
.statuscontainer {
  width: 400px;
  margin: 50px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px 1px #a9a7a7;
  background: #fff;
}

.statusheader {
  display: flex;
  /* align-items: center; */
}

.statusheader span {
  font-size: 18px;
  flex-grow: 1;
  color: #292d33;
  font-weight: bold;
}

.editbutton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 30px;
}

.editbutton svg > circle:hover {
  fill: green;
}

.statuscontent {
  margin-top: 10px;
  position: relative;
}

#statustext {
  display: block;
  margin-bottom: 10px;
}

#statusinput {
  display: none;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.Edit {
  display: flex;
  flex-direction: column;
}

.Edit .editcamera {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 26px;
  color: #575757;
}

.DeleteStatus {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  color: orangered;
  cursor: pointer;
}

.CrossMarkBlock {
  position: absolute;
  top: 2px;
  left: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}

.CancelStatus {
  /* font-size: 25px;

    color: black; */
  color: #444;
  /* Customize the icon color */
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CancelStatus svg {
  font-size: 12px;
}

.savebutton {
  background-color: #4caf50;
  color: white;
  margin: 5px;
  /* margin-right: 10px; */
  padding: 6px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.cancelbutton {
  background-color: #f44336;
  color: white;
  margin: 5px;
  padding: 6px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.TextBox {
  width: 100%;
  height: 390px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

.TextBox1 {
  width: 100%;
  height: 440px;
  text-align: center;
  font-size: 18px;
  background-color: transparent;
  border: none;
  resize: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.TextBox1 > a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.TextBox1 > a:hover {
  color: rgb(180, 168, 168);
}

.ButtonBlock {
  display: flex;
  justify-content: flex-end;
}

.chatMessageInput {
  padding: 10px;
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background: transparent;
  color: black;
}

.chatMessageInput1 {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background: transparent;
  color: black;
  margin: 4px 0px;
}

.chatSubmitButton {
  background-color: #f16f1d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.MessageBlock {
  position: absolute;
  bottom: 30px;
  left: 48px;
  width: 70%;
}

.SendBlock {
  position: absolute;
  bottom: 30px;
  right: 17px;
}

.editcameradisplay {
  display: none;
}

.NoStatus {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.NoStatus > h6 {
  font-size: large;
  color: #f16f1d;
}

.noStatusView {
  height: 93%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2%;
}

.noStatusView > img {
  height: 310px;
  /* width: 300px; */
}

.noStatusView > h3 {
  color: #f16f1d;
}

.StatusViewClick {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2%;
}

.StatusViewClick > img {
  height: 140px;
}

.StatusViewClick > h3 {
  color: #f16f1d;
}

.ListStatus {
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 45px;
  color: black;
  cursor: pointer;
}

.error {
  padding: 10px;
  width: 90%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background: transparent;
  color: black;
  border: 1px solid red;
  /* outline: 1px solid red; */
}

.error::placeholder {
  color: red;
}

.StatusColorGreen {
  color: #ffff00;
  font-size: 27px;
}

.MessageBlock {
  display: flex;
  align-items: baseline;
}

.chatMessageInput {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #f16f1d;
  margin: 0 5px 5px 0;
  outline: none;
}

.chatSubmitButton {
  background-color: #f16f1d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin: 0 5px 6px 0;
}
.BackBtn {
  display: none;
}
@media (max-width: 500px) {
  .UserStatusBlock {
    width: 30%;
  }

  .ListUser {
    font-size: x-large;
  }

  .MyStatusBlock {
    width: 70%;
  }

  .NoStatus {
    text-align: center;
    font-size: small;
  }

  .noStatusView > img {
    height: 190px;
    width: 180px;
  }

  .noStatusView > h3 {
    font-size: medium;
  }

  .StatusViewClick > img {
    height: 70px;
  }

  .StatusViewClick > h3 {
    font-size: medium;
  }
}
@media (max-width: 550px) {
  .UserStatusBlock {
    width: 100%;
  }
  .MyStatusBlock {
    display: none;
  }
  .parent.backActive {
    width: 100%;
  }
  .parent.backActive .UserStatusBlock {
    display: none;
  }

  .parent.backActive .MyStatusBlock {
    display: block;
    width: 100%;
    flex-basis: 100%;
  }
  .BackBtn {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .MyStatus > div > div {
    height: 100vh;
  }
  /* .chatBoxTop > main div {
    width: 80%;
  } */
}
