@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kurale&display=swap");
@import "rsuite/dist/rsuite.css";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
}

body {
  font-family: "Lato", sans-serif;
  background-color: #efefef;
}

h1,
h2,
h3,
h4,
h5,
h2 a,
label {
  color: rgb(40, 60, 73);
}

table {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border: 1px solid #efefef;
  padding: 5px;
  text-align: center;
}

th {
  padding: 4px 5px;
  /* border: 1px solid #aaa6a6; */
  font-size: 13px;
}

tr,
td {
  border: 1px solid #aaa6a6c4 !important;
  font-size: 13px;
  padding: 0 4px;
}

thead {
  background: rgb(40 60 73);
  color: #fff;
  text-transform: capitalize;
}

tbody tr:nth-child(odd) {
  background-color: #efefef96;
}

tbody tr:nth-child(even) {
  background-color: #fff;
}

tr td a {
  color: #f16f1d;
  font-size: 22px;
}

.active {
  /* border: 1px solid #f16f1d !important; */
  color: #f16f1d !important;
  background: #f16e1d84 !important;
}

.newActive {
  border: 1px solid red !important;
  color: red !important;
  background: green !important;
}

#textdanger {
  color: red;
  padding: 0px 5px;
}

.color_chat {
  color: #22c55e;
}

.rdw-editor-main {
  max-height: 450px;
}
.rs-btn-close .rs-icon {
  vertical-align: bottom;
  color: crimson;
  font-size: large;
}
p + p {
  margin-top: 0 !important;
}
