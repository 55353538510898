#adminDashboardBlock {
  overflow: hidden;
  width: 100%;
}

#adminDashboardBlock > article {
  width: 100%;
  /* margin-top: 50px; */
  background: #efefef;
  display: flex;
  height: 100vh;
}

#adminDashboardBlock > article > aside.sidebarBlock {
  position: fixed;
  margin-top: 50px;
  flex-basis: 15%;
  background-color: #fff;
  border-top: 1px solid #efefef;
  height: 100vh;
  width: 11%;
}

#adminDashboardBlock > article > aside.sidebarBlock ul {
  display: flex;
  flex-direction: column;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li {
  width: 100%;
}

/* #adminDashboardBlock > article > aside.sidebarBlock ul li.active:active{
  background: purple !important;
} */
/* .ActiveClass:active{
background: #000;
} */

#adminDashboardBlock > article > aside.sidebarBlock ul li a {
  background: #eeeeee26;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #efefef;
  padding: 10px 5px;
  color: rgb(40 60 73);
  text-decoration: none;
  font-size: 14px;
  gap: 14px;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li > a:hover {
  color: #f16f1d;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li a span:first-child {
  position: relative;
}

#adminDashboardBlock > article > aside.sidebarBlock ul li span:last-child {
  flex-basis: 90%;
  margin: 0 14px;
}

#adminDashboardBlock
  > article
  > aside.sidebarBlock
  ul
  li
  a:first-child
  span
  svg {
  color: #020202;
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  left: 0px;
  top: -4px;
}

#adminDashboardBlock > article > aside.mainadminBlock {
  margin-top: 50px;
  /* margin-left: 11%; */
  /* flex-basis: 89%; */
  /* background-color: #fff; */
  height: 100vh;
  width: 100%;
}

/* #adminDashboardBlock article ul li a:hover {
  background: rgb(238, 238, 238) !important;
  transition: ease all 0.7s;
} */

#adminDashboardBlock article table tr td {
  position: relative;
}

#adminDashboardBlock article table tr tr td a {
  color: green;
  font-size: 27px;
  position: absolute;
  top: 0;
}

#BatchGridLayout article {
  display: flex;
  background: #efefef;
  padding: 10px 20px;
  gap: 7px;
  flex-wrap: wrap;
  height: 81vh;
  overflow: auto;
}

#BatchGridLayout article .container {
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 0 10px 8px rgba(255, 166, 0, 0.044);
  width: 240px;
  height: 30vh;
  height: 30vh;
  /* background-color: #7280eb3f; */
  display: flex;
  width: 18.5vw;
  -webkit-box-align: center;
  align-items: flex-start;
  justify-content: center;
}

#BatchGridLayout article .container1 {
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background: #f16f1d; */
  background: #f7e6d4;

  width: 18.5vw;
  height: 30vh;
  justify-content: center;
  align-items: flex-start;
  gap: 3%;
  border: 0.3px ridge rgb(153, 153, 153);
}
.Search {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 6px;
  padding: 3px 5px;
  margin: 5px 5px 0px 20px;
  width: 25%;
  box-shadow: 0 0 10px 3px #ff61241f;
  border: 2px solid orange;
}

/* @media (min-width: 1066px) and (max-width: 1250px) {
  #BatchGridLayout article .container {
    width: 280px;
    height: 32vh;
    background-color: #333;
  }
}

@media (min-width: 1066px) and (max-width: 1250px) {
  #BatchGridLayout article .container1 {
    width: 280px;
    height: 32vh;
  }
} */
.Closedbatch {
  flex-basis: 24.3%;
  background: rgb(161, 204, 240);
  padding: 10px;
}

.ClosedbatchH1 {
  font-size: 15px;
  padding: 0px 0 5px;
  color: rgb(103, 58, 183);
  font-weight: bold;
  border-bottom: 2px solid rgba(248, 248, 248, 0.795);
  margin: 5px 0 10px;
  width: fit-content;
  border-radius: 1px 5px 5px 2px;
  line-height: 0;
}

#BatchGridLayout article .container h1 {
  font-size: 15px;
  padding: 0px 0 5px;
  color: #fa7826;
  font-weight: bold;
  border-bottom: 2px solid rgb(103 58 183 / 8%);
  margin: 5px 0 10px;
  width: fit-content;
  border-radius: 1px 5px 5px 2px;
  line-height: 20px;
  text-transform: capitalize;
}

#BatchGridLayout article .container1 h1 {
  font-size: 15px;
  padding: 0px 0 5px;
  color: #fa7826;
  font-weight: bold;
  border-bottom: 2px solid rgb(103 58 183 / 8%);
  margin: 5px 0 10px;
  width: fit-content;
  border-radius: 1px 5px 5px 2px;
  line-height: 20px;
  text-transform: capitalize;
}

.container p {
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  gap: 10px;
  margin: 10px 0;
  text-transform: capitalize;
  color: #e5e7eb;
  align-items: center;
}

.container p span {
  color: #000;
  display: flex;
  gap: 5px;
  font-size: 12px;
}

.content {
  color: #fff;
  display: flex;
  font-size: 10px;
}

.container a {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 12px;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid #f16f1d;
  transition: ease all 0.7s;
  background: #f16f1d;
}

.container1 a {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 12px;
  color: #fff;
  padding: 2px 6px;
  border-radius: 4px;
  display: inline-block;
  border: 1px solid #f16f1d;
  transition: ease all 0.7s;
  background: #f16f1d;
}

.Linkbtn {
  text-transform: capitalize;
  text-decoration: none;
  font-size: 12px;

  padding: 2px 6px;
  border-radius: 4px;

  /* border: 1px solid #fff; */
  transition: ease all 0.7s;
  /* background: #fff; */
  justify-content: space-between;
  display: flex;
  background: #f16f1d;
}

.Linkbtn > button {
  font-size: 12px !important;
  color: #fff;
  padding: 2px 6px !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  transition: ease all 0.7s !important;
  background: #f16f1d;
  display: flex;
}

.Linkbtn > button :hover {
  background: #ed6510 !important;

  /* background: #059669 !important; */
  /* border: 1px solid #059669 !important; */
  color: #fff !important;
}
.Linkbtn1 {
  background: #f16f1d;
}
.container1 a:hover {
  background: #ed6510 !important;

  /* background: #059669; */
  /* border: 1px solid #059669; */
  color: #fff;
}

.container1 a span {
  margin: 1px 5px 0 0px;
  display: inline-block;
}

.container a:hover {
  background: #f16f1d;
  border: 1px solid #f16f1d;
  color: #fff;
}

.container a span {
  margin: 1px 5px 0 0px;
  display: inline-block;
}

.headingcard {
  display: flex;
  justify-content: space-between;
}

.headingcard .BellIcon {
  display: flex;
  font-size: 20px;
  color: black;
}

.notification {
  font-size: 20px;
  line-height: 28px;
}

.NoNotification {
  display: none;
}

.Search input {
  width: 100%;
  padding: 4px 3px;
  border: none;
  background: rgba(102, 62, 183, 0);
  outline: none;
}

.Search span {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
}

.SearchBtnGrpBatch {
  border: 2px solid orange;
  padding: 6px 10px;
  border-radius: 6px;
  font-weight: "bolder";
  background-color: #333;
  color: #fff;
}

#reactpaginate {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 10px;
  margin-top: 15px;
}

#reactpaginate ul {
  display: inline-block;
  margin-left: 20px;
  padding-left: 0;
}

#reactpaginate li {
  display: inline-block;
  border: 1px solid #ffad06;
  color: #000;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 30px;
  margin-bottom: 5px;
}

#reactpaginate li a {
  padding: 2px 6px;
  display: inline-block;
  color: #000;
  outline: none;
  text-decoration: none;
}

#reactpaginate > li:hover {
  background: #ffad06 !important;
}

#reactpaginate > li a:hover {
  background: #ffad06 !important;
}

#reactpaginate > li.active {
  background: #f16f1d;
  outline: none;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.chatAndQr {
  display: flex;
  justify-content: space-between;
}

.QRCODE {
  width: 13%;
  height: 26px;
  background: transparent;
}

.QRCODE svg {
  font-size: 33px;
  color: #1a1d24;
}

.formcontainer {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.formgroup {
  margin-bottom: 20px;
}

.formgroup > label {
  display: block;
  font-weight: bold;
}

.messageinputall textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  overflow: scroll;
  height: 100px !important;
}

.selectcontainer {
  width: 100%;
}

/* Style ReactSelect components */
.react-select__control {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.react-select__menu {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: none;
}

.react-select__option {
  padding: 10px;
}

.sendbutton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.sendbutton:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-top: 5px;
}

.messageinput {
  flex: 1;
  resize: none;
  min-height: 20px;
  max-height: 100px;
  /* Adjust as needed */
  border: 1px solid #ccc;
  padding: 5px;
  transition: min-height 0.2s ease-in-out;
  outline: 1px solid gray;
}

.Messagebtn {
  width: 100%;
}

.MessageBlock {
  display: flex;
  flex-direction: column;
}

.FileShare {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.FileShare {
  font-size: 25px;
  font-weight: bold;
}

.AllBatchHead {
  color: #dd7b03;
  border-bottom: 1px solid #dd7b03;
  font-size: 19px;
}

.Aleartmsg {
  color: #000;
  font-size: 14px;
}

.Dropdown {
  margin-bottom: 20px;
}

.BtnBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.MessageBtn {
  padding: 10px 20px !important;
  font-size: 14px !important;
}

.MsgFormBlock {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ccc;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
  justify-content: space-between;
  height: auto;
}

.messageinputall {
  flex: 1;
  border: 1px solid #ccc;
  padding: 5px;
  transition: min-height 0.2s ease-in-out;
  outline: 1px solid gray;
  width: 100%;
  min-height: 50px;
  /* max-height: 90px; */
  overflow: hidden;
  padding: 8px;
  font-size: 14px;
  border: none;
  outline: none;
  resize: none;
  background-color: #fff;
  border-radius: 5px;
}

.FileShare {
  cursor: pointer;
  margin: 0px 5px;
}

.MessageBtn {
  background: #f16f1d !important;
}

.MessageBtn:hover {
  background: #fa6101 !important;
}

.MsgSendBtn {
  background: #f16f1d !important;
}

.MsgSendBtn:hover {
  background: #fa6101 !important;
}

.HeadBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
/* Hide the actual file input */
.fileInput {
  display: none;
}

/* Style the label to look like a button */
.fileButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.fileButton:hover {
  background-color: #0056b3;
}

.fileButton:active {
  background-color: #004080;
}
.backtotext {
  position: absolute;
  top: 0;
  left: 0;
}
/* media query for cards */
@media (min-width: 200px) and (max-width: 500px) {
  #BatchGridLayout article {
    /* padding: 10px 20px; */
    margin: 0 auto;
    /* justify-content: center; */
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  #BatchGridLayout article .container {
    height: 32vh;
    /* background-color: #bd8383; */
    display: flex;
    width: 100vw;
    -webkit-box-align: center;
    /* align-items: center; */
    align-items: flex-start;
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  #BatchGridLayout article .container1 {
    height: 32vh;
    width: 100vw;
    -webkit-box-align: center;
    /* align-items: center; */
    align-items: flex-start;
  }
  .Search {
    width: 40%;
  }
}

@media (min-width: 500px) and (max-width: 650px) {
  #BatchGridLayout article {
    /* padding: 10px 40px; */
    margin: 0 auto;
    justify-content: center;
  }
  .Search {
    width: 40%;
    margin: 0 auto;
  }
}

@media (min-width: 500px) and (max-width: 650px) {
  #BatchGridLayout article .container {
    height: 32vh;
    /* background-color: #bd8383; */
    display: flex;
    width: 39vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 500px) and (max-width: 650px) {
  #BatchGridLayout article .container1 {
    height: 32vh;
    width: 39vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 650px) and (max-width: 800px) {
  #BatchGridLayout article {
    /* padding: 10px 25px; */
    margin: 0 auto;

    justify-content: center;
  }
  .Search {
    width: 40%;
    margin: 0 auto;
  }
}
@media (min-width: 650px) and (max-width: 800px) {
  #BatchGridLayout article .container {
    height: 30vh;
    /* background-color: #bd8383; */
    display: flex;
    width: 40vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 650px) and (max-width: 800px) {
  #BatchGridLayout article .container1 {
    height: 30vh;
    width: 40vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 800px) and (max-width: 1009px) {
  #BatchGridLayout article {
    padding: 10px 20px;
    /* justify-content: center; */
  }
}
@media (min-width: 800px) and (max-width: 1009px) {
  #BatchGridLayout article .container {
    height: 32vh;
    /* background-color: #bd8383; */
    display: flex;
    width: 30vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 800px) and (max-width: 1009px) {
  #BatchGridLayout article .container1 {
    height: 32vh;
    width: 30vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 1009px) and (max-width: 1270px) {
  #BatchGridLayout article {
    /* padding: 10px 40px; */
    margin: 0 auto;
    /* justify-content: center; */
  }
}
@media (min-width: 1009px) and (max-width: 1270px) {
  #BatchGridLayout article .container {
    height: 32vh;
    display: flex;
    width: 22.5vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 1009px) and (max-width: 1270px) {
  #BatchGridLayout article .container1 {
    height: 32vh;
    width: 22.5vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 1270px) and (max-width: 1370px) {
  #BatchGridLayout article {
    padding: 10px 50px;
    /* justify-content: center; */
  }
}
@media (min-width: 1270px) and (max-width: 1370px) {
  #BatchGridLayout article .container {
    height: 32vh;
    /* background-color: #7280eb3f; */
    display: flex;
    width: 17.5vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}

@media (min-width: 1270px) and (max-width: 1370px) {
  #BatchGridLayout article .container1 {
    height: 32vh;
    width: 17.5vw;
    -webkit-box-align: center;
    align-items: flex-start;
  }
}
