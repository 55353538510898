/* new table css */
.filterBlock {
    background-color: #2d2d2d;
    margin-top: 0 !important;
    padding-bottom: 5px;
}

.searchIcon {
    position: absolute;
    right: 5px;
    bottom: 2px;
    background-color: transparent;
}

.studentNameList {
    font-size: small;
    padding: 8px;
    border: none;
    border-radius: 4px;
    width: 100%;
    font-weight: 400;
}

.closeborder {
    font-size: 14px;
    background: rgba(233, 12, 12, 0.678);
    border: none;
    border-radius: 4px;
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    padding: 2px 4px;
    border: 1px solid rgba(233, 12, 12, 0.678);
}

.closeborder:hover {
    color: #fff;
    background: #dc2626;
    border: 1px solid #dc2626;
}

.reopen {
    font-size: 14px;
    padding: 2px 5px;
    background: #2dd4bf;
    border: none;
    border-radius: 4px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border: 1px solid #2dd4bf;
}

.reopen:hover {
    color: #fff;
    background: #14b8a6;
    border: 1px solid #14b8a6;
}

.permanentUnBlock {
    background: #2dd4bf;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    border: 1px solid #2dd4bf;
}

.permanentUnBlock:hover {
    background: #14b8a6;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    border: 1px solid #14b8a6;
}

.permanentBlock {
    background: rgba(233, 12, 12, 0.678);
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    border: 1px solid rgba(233, 12, 12, 0.678);
}

.permanentBlock:hover {
    background: #dc2626;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    border: 1px solid #dc2626;
}

.permanentBlock1 {
    background: rgba(233, 12, 12, 0.678);
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    /* border: red; */
    border: 1px solid rgba(233, 12, 12, 0.678);
    width: 100px;
}

.permanentBlock1:hover {
    background: #dc2626;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    /* border: red; */
    border: 1px solid #dc2626;
}

/* pagination css */
#reactpaginate {
    width: 90%;
    display: flex;
    justify-content: center;
    margin: 10px;
}

#reactpaginate ul {
    display: inline-block;
    margin-left: 20px;
    padding-left: 0;
}

#reactpaginate li {
    display: inline-block;
    border: 1px solid #f16f1d;
    color: #000;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 10px;
    margin-bottom: 5px;
}

#reactpaginate li a {
    padding: 2px 5px;
    display: inline-block;
    color: #000;
    outline: none;
    text-decoration: none;
    background-color: transparent;
}

#reactpaginate li:hover {
    background: #f16f4d !important;
}

#reactpaginate li a:hover {
    background: #f16f4d !important;
}

#reactpaginate li .active {
    background: #f16f4d;
    outline: none;
}

.loader {
    /* width: 100%; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; */
    /* width: 80vw; */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

}

/* Logout alert */
.logoutAlertBlock {
    background-color: #efefef;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: auto;
    border: 3px solid #f16f4d;
    margin: 0;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 1000px rgba(15, 12, 11, 0.493);
}


.logoutAlertBlock>h4 {
    color: gray;
    width: 100%;
    text-align: center;
    font-size: medium;
}

.logoutAlertBlock>div {
    width: 100%;
    /* height: 40%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 10%; */
    gap: 10%;
}

.logoutAlertBlock>div>button {
    width: 30%;
    height: 60%;
    font-weight: bold;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    padding: 4px 0;
    border-radius: 6px;
}

.logoutAlertBlock>div>button:first-child {
    border: 1px solid #f16f4d;
    color: #f16f4d;
    background-color: #efefef;
}

.logoutAlertBlock>div>button:last-child {
    border: 1px solid #f16f4d;
    background-color: #f16f4d;
    color: #efefef;
}

.logoutAlertBlock>div>button:first-child:hover {
    transition: 0.5s all ease-in-out;
    background-color: #f16f4d;
    color: #efefef;
}

.logoutAlertBlock>div>button:last-child:hover {
    transition: 0.5s all ease-in-out;
    color: #f16f4d;
    background-color: #efefef;
}


/* update student block */

.btns {
    background-color: transparent;
    border: none;
    z-index: 999;
}