.details-container {
    margin: 0 auto;
    border-radius: 8px;
    width: 100%;
    max-height: 93vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.details-content {
    padding: 10px;
}

.details-content h2 {
    color: #f16f1d;
    font-size: xx-large;
    text-align: center;
}

.details-section {
    margin-bottom: 10px;
}

.details-section h3 {
    margin-bottom: 2px;
    color: #f16f1d;
    font-size: x-large;
}

.details-section p {
    margin: 5px 0;
    color: #333;
    font-weight: 400;
    width: 48%;
}

.details-section p strong {
    color: #f57c00;
    width: 40%;
    display: inline-block;
}

.normal-details {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.normal-details p {
    flex: 1 1 30%;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    margin-top: 10px;
}

.accept-button, .reject-button {
    padding: 6px 20px;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
}

.accept-button {
    background-color: green;
}

.reject-button {
    background-color: crimson;
}

.noDataText {
    text-align: center;
    height: 100%;
    width: 100%;
    color: #f16f1d;
    margin-top: 10%;
}