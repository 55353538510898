#adminDashboardBlock {
    height: 90vh;
    padding-top: 50px;
    background-color: #fff;
}

.helpChatContainer {
    width: 100%;
    height: calc(100%);
    padding: 4px;
}