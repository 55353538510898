#chatBoxSection article {
  width: 100%;
  background-color: #efefef;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
}

.chattype {
  flex-basis: 15%;
  height: 90vh;
  background: #cccccc36;
  padding: 10px;
}
.chatMenu {
  flex-basis: 25%;
  flex-basis: 25%;
  height: 100vh;
  background: #fff;
  padding: 10px;
}
.chatBox {
  flex-basis: 75%;
  height: 100vh;
  position: relative;
}
.chatBox > div {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chatBoxStore {
  /* flex-direction: column-reverse; */
  /* height: 556px; */
  overflow: auto;
  /* margin-top: 44px; */
  height: 90%;
}

.chatMenu h1 {
  font-size: 14px;
  text-transform: capitalize;
  padding: 0px 0;
  color: rgb(103 58 183);
  text-shadow: 0px 0px 0px rgb(103 58 183);
  line-height: 0px;
}

.chatMenu input {
  padding: 7px;
  border: none;
  width: 100%;
  border-radius: 5px;
  margin: 6px 0 6px;
  background: transparent;
  border: 1px solid #f16f1d;
  outline: none;
  font-size: 13px;
  text-transform: capitalize;
  /* border: 1px solid orange; */
}

.chatMenuWrapper {
  position: relative;
  height: 80vh;
}
.chatMenuWrapper1 {
  position: relative;
  /* height: 75vh; */
}
.ConversationBlock {
  overflow-y: scroll;
  height: 96%;
}
.searchIcon {
  position: relative;
  padding: 16px 16px;
  background-color: #f16f1d;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
.searchCancel {
  position: relative;
  padding: 8px 8px;
  background-color: rgb(216, 11, 11);
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
.searchCancel svg {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SearchText {
  flex-basis: 85%;
}
.searchIcon svg {
  /* position: absolute;
    right: 8px;
    top: 11px;
    color: #666; */
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-position: center;
}
.listUser {
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea8;
  border-radius: 5px;
  margin: 3px 0;
  width: 100%;
  /* text-transform: capitalize; */
}
.listUser1 {
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea8;
  border-radius: 5px;
  margin: 3px 0;
  /* text-transform: capitalize; */
}
.listUser:hover {
  background: #adacaca8;
}
.conversation {
  color: rgb(40 60 73);
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-transform: capitalize;
}
.conversationnnnn {
  color: red;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-transform: capitalize;
  z-index: 111111111111111111;
}
.notify {
  background: rgba(7, 192, 7, 0.877);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* color: chartreuse; */
}
.notify span {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
.nonotify {
  display: none;
}
.chatBoxBottom {
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.3);
  /* bottom: -1px; */
  display: flex;
  background: #fff;
  padding: 10px;
  height: 10vh;
  /* position: absolute; */
  z-index: 1;
  width: 100%;
}

.noConversationText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 90vh;
}

.chatBoxBottom > textarea {
  color: #1f2937;
  background: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  width: 99%;
  outline: 1px solid #ccc;
  height: 80%;
  /* outline: 1px solid greenyellow; */
}
.chatBoxBottom > textarea::placeholder {
  color: #64748b;
}
.chatBoxBottom button {
  /* width: auto; */
  border: none;
  padding: 0px 22px;
  background: #f16f1d;
  color: #fff;
  display: inline-block;
  border-radius: 6px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid #f16f1d;
  cursor: pointer;
  outline: none;
  /* position: fixed; */
  height: 80%;
  width: 80px;
}

.chatBoxBottom button:hover {
  background-color: #ff3e4e;
  border: 1px solid #ff3e4e;
}
.message.own {
  align-items: flex-end;
}

.sendermsg {
  width: 80%;
  margin-top: 22px;
  padding: 0px 5px;
  float: right;
}

.sendermsg > main {
  background-color: #fff;
  padding: 4px 18px;
  padding-left: 12px;
  border-radius: 0px 16px 16px 16px;
  /* margin: 0px 30px; */
  float: right;
  border-bottom: 1px solid rgb(103 58 183 / 33%);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  flex-direction: column;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
.sendermsg > main > div > div {
  /* background: #000; */
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
}
.recivermsg {
  width: 80%;
  margin: 0px 15px;
  float: left;
  padding: 0px 20px;
}
.unread_mesg {
  width: 100%;
  margin: 0px 15px;
  padding: 0px 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  font-size: larger;
  font-weight: 800;
  color: #111;
}
.unread_mesg h4 {
  text-decoration: underline;
}

.recivermsg > main {
  background-color: #14b8a6;
  padding: 4px 18px 4px 12px;
  border-radius: 12px 0px 16px 16px;
  margin: 4px 0px;
  float: left;
  border-bottom: 1px solid rgb(103 58 183 / 19%);
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}
.recivermsg > main div p a {
  color: #164ae0;
  /* color: #fff; */
}
/* .chatBoxTop {
  overflow-y: hidden;
  position: relative;
  padding-right: 10px;
  margin-bottom: 50px;
} */
.active {
  background-color: #666;
}

.sendermsg time {
  font-size: 10px;
  color: #888;
}

.recivermsg time {
  color: #f1f5f9;
  font-size: 10px;
}
.sendermsg div:nth-child(2) {
  float: right;
  text-align: left;
}

.recivermsg div:nth-child(2) {
  float: left;
  text-align: left;
}

.chatBoxTop div p {
  white-space: pre-wrap;
  word-break: break-word;
  font-family: "lato", sans-serif;
}

.chatBoxTop > main {
  background: #fff;
  padding: 7px;
  font-size: 15px;
  text-transform: capitalize;
  color: #111;
  border-top: 1px solid #cccccc1f;
  border-bottom: 1px solid #ccc;
  /* position: fixed; */
  width: 100%;
  /* z-index: 1; */
  height: 7vh;
}
.chatBoxTop > main div {
  align-items: center;
  display: flex;
  width: 41%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.chatBoxTop > main div span {
  font-size: 14px;
  color: #666;
}

#UserBlock h2 {
  display: flex;
  gap: 5px;
  font-weight: 300;
}

.faIcon {
  font-size: 15px;
  color: green;
  padding: 3px 2px;
  display: inline-block;
}

#online {
  color: green;
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
}
#offline {
  color: red;
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
}
.Username {
  font-size: 43% !important;
  font-weight: bold;
  line-height: 20px;
}
.customStylesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffffad;
  width: 40%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UploadfileContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 20px;
  color: #475569;
  height: 80%;
}
.UploadfileContainer span {
  font-size: 120%;
  padding: 10px;
}

.Messageeee:nth-child(1),
.Messageeee:nth-child(1) {
  margin-top: 48px;
}

/* Modal css starts here */

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 250px;
  height: 170px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

/* .modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
    border: 1px solid lightgray;
  border-radius: 10px;
  background: #efefef;
} */

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.Broadcast {
  padding: 2px 9px;
  background: #f16f1d;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: bold;
}

.Broadcast:hover {
  background-color: #fb6101;
}

.Head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Emptymsg {
  display: none;
}
.Messageeee {
  padding: 0px;
}

.displaynone {
  display: none;
}
.broadcast {
  font-size: 10px;
  display: flex;
  position: relative;
  /* background: #2c3e50; */
  color: #666;
  /* font-size: 15px; */
}
.broadcast svg {
  position: absolute;
  top: 2px;
  font-size: 15px;
  right: -11px;
}
.lastmsg {
  font-size: 11px;
  color: #666c66;
  display: flex;
  justify-content: space-between;
}
.Nolastmsg {
  font-size: 11px;
  color: #666c66;
  display: flex;
  justify-content: space-between;
}

.tickmark {
  width: 100%;
  display: flex;
  /* gap: 5px; */
  justify-content: space-between;
}
.tickmark div {
  display: flex;
  gap: 5px;
}
.icontick {
  font-size: 12px;
}
.time {
  font-size: 12px;
  color: #666c66;
}
.attachfile {
  font-size: 20px;
}
.noConversation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 75vh;
}
.Download {
  color: #3498ff;
  display: block;
  text-decoration: underline;
}
.Download:hover {
  color: #2563eb;
}
.sendermsg > main > div .Document {
  color: black;
  text-decoration: none;
}
.sendermsg > main > div .Document:hover {
  text-decoration: none;
  color: black;
}
.recivermsg > main div .Document {
  color: white;
  text-decoration: none;
}
.recivermsg > main > div .Document:hover {
  text-decoration: none;
  color: white;
}
.messageTop pre {
  /* font-family: Lato, sans-serif;
  font-size: 14px;
  color: #fff;
  /* background-color: rgba(143, 142, 142, 0.12); */
  /* padding: 5px;  */
  white-space: pre-wrap; /* Ensure long text wraps */
  word-wrap: break-word;
}

.batchname {
  font-size: 15px;
  line-height: 15px;
  color: #f16f1d;
}
.loadmoreBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.loadmoreBlock button {
  width: 72%;
  background: rgb(52 152 255);
  color: black;
  /* border: blue; */
  border-radius: 5px;
}
.NewStudents {
  display: flex;
  justify-content: space-between;
}
/* .ForwardMsgBlock{
  line-height: 6px;
  padding: 6px 6px;
    }
    #menubutton{
      line-height: 6px;
  padding: 6px 6px;
    } */
.ForwardMsgBlock div button {
  padding: 5px 5px !important;
  color: #34c3ff;
}
.Searchhh > input {
  padding: 3px;
  border-radius: 5px;
}
.btndownload {
  background: none;
  color: #3498ff;
  display: block;
  text-decoration: underline;
}
.btndownload:hover {
  color: #2563eb;
}
.btndownload1 {
  background: none;
}
.loader {
  /* background: #000;
      padding: 50px; */
  /* width: 100%; */
  /* height: 90vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}
.AudioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AudioBlock > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AudioBlock > div > main {
  font-size: 18px;
  padding-right: 5px;
}
.StartAudio {
  color: black;
}
.StopAudio {
  color: red;
}
.SendAudioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PlayAudio {
  color: #16a34a;
}
.DeleteAudio {
  color: red;
  font-size: 22px;
  padding: 0px 8px 0px 4px;
}
.SendAudio {
  padding: 5px 16px;
  border: 2px solid green;
  border-radius: 5px;
  color: #fff;
  background: green;
  font-size: 17px;
  text-transform: capitalize;
}
.AudioNot {
  display: none;
}
.recordingAnimation {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.recordingAnimation.active {
  opacity: 1;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.BlockConersation {
  display: flex;
  justify-content: space-between;
}
.HrBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 5px;
  padding: 5px 8px 0px 5px;
}
.HrBlock > a > span:nth-child(1) {
  font-size: 20px;
  cursor: pointer;
  color: #3081c8;
}
.HrBlock > span:nth-child(2) {
  font-size: 20px;
  cursor: pointer;
  /* color: #3081c8; */
}

.loadmoreDisplaynone {
  display: none;
}
.SearchBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  align-items: center;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  margin-top: 5px;
  padding: 4px;
}
.SearchArticle {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 2px;
}
.BatchGroup {
  font-size: 10px;
  color: #2c3e50;
  padding: 5px;
}
.StatusText {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
}
.StatusText1 {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
}
.copiedText {
  background-color: rgba(58, 56, 56, 0.37);
  padding: 5px;
  border-radius: 5px;
  border-left: solid;
  width: 90%;
}
.CrossMark {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 22px;
  font-weight: bold;
  color: red;
}
.ropdowncontent {
  position: absolute;
  top: -100px;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 100px;
}
.ropdowncontent > ul {
  text-decoration: none;
  list-style: none;
}
.FileShare:hover {
  background-color: #efefef;
  border-radius: 5px;
}
.HeaderPoll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #f16f1d;
}
.HeaderPoll > span {
  margin-right: 20px;
}
.modalcontent {
  background-color: #fefefe;
  margin: 15px auto;
  padding: 20px;
  /* border: 1px solid #888; */
  width: 100%;
  max-width: 600px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background: #efefef;
  /* text-align: center;  */
}
.InputQue {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-bottom: 2px solid gray;
}
.InputQue:focus {
  border-bottom: 2px solid #4caf50;
}

.AddOptions {
  background-color: lightgray;
  color: #666;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 100;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SubmitPoll {
  background-color: transparent;
  color: #f16f1d;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 40px;
}
.OptionBlock {
  display: flex;
  flex-direction: column;
}
.ClearOption {
  background: transparent;

  padding-left: 10px;
}
.Allow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.datePicker {
  margin: 10px 0;
}
.Pollhead {
  display: flex;
  justify-content: flex-start;
  color: #fb6101;
  text-transform: capitalize;
  font-weight: bold;
}
.AnswerPoll {
  background-color: #f16f1d; /* Theme color for the "Answer" button */
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px; /* Smaller font size */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 2px;
}
.AnswerPoll:hover {
  background-color: darken(#f16f1d, 10%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
}
.AnswerView {
  background-color: #7e57c2; /* Theme color for the "Answer" button */
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 4px 8px;
  font-size: 12px; /* Smaller font size */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 2px;
}
.AnswerView:hover {
  background-color: darken(#7e57c2, 10%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 6px;
}
.NotySort {
  cursor: pointer;
  font-size: 20px;
  color: #16a34a;
}
.ViewPollBlock {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  max-height: 550px;
}
.MainContainer {
  width: 500px;
}
.options {
  padding: 5px;
  background-color: #efefef;
  border-radius: 5px;
}
.Voters {
  display: flex;
  justify-content: space-between;
}
/* Style for the container holding the checkbox and label */
.polloption {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

/* Style for the checkbox input */
.polloption > input[type="checkbox"] {
  margin-right: 10px;
}

/* Style for the label associated with the checkbox */
.polloption > label {
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  background: #efefef;
  padding: 5px;
  border-radius: 6px;
}

/* Style for the label when hovering */
.polloption > label:hover {
  text-decoration: underline;
}
.TextAlign {
  white-space: pre-wrap;
  word-break: break-word;
}
.deleteicon {
  font-size: 20px;
  color: red;
}
.QuestionBlock {
  display: flex;
  justify-content: space-between;
}
.deleteicon > svg {
  border: 2px solid red;
  border-radius: 50%;
}
.errormessage {
  color: red;
}
.InputOption {
  display: flex;
}
.AddPoll {
  font-size: 20px;
  color: #16a34a;
}
.AddPoll > svg {
  border: 2px solid #16a34a;
  border-radius: 50%;
}
.SpinnerBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Spinner {
  font-size: 20px;
}
.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}
.ListForward {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea9;
  border-radius: 5px;
  margin: 5px 0;
  color: #475569;
  font-weight: 800;
}
.ListForward:hover {
  background-color: #c6c2c2c1;
}
.chatBoxTop {
  /* overflow-y: auto; */
  /* position: relative; */
  height: 85vh;
}
.FormBlock {
  height: 15vh;
  position: relative;
}
.replyBlock {
  position: absolute;
  top: -52px;
  width: 100%;
}

.ForwardBlock svg {
  font-size: 20px;
  color: green;
  border-radius: 50%;
}
.ForwardPanel {
  width: 100%;
  background-color: #fff;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 30px;
  font-size: 20px;
  /* border: 1px solid greenyellow; */
  color: black;
  background-color: #fff;
  box-shadow: 0px 0px 5px -1px;
}
.CrossMarkCancel {
  color: red;
  font-weight: bold;
  font-size: 25px;
}

.BatchCheck {
  margin-right: 0 !important;
}
.BackBtn {
  display: none;
}
@media (min-width: 200px) and (max-width: 700px) {
  .BatchCheck {
    width: 90%;
  }
}
@media (min-width: 700px) and (max-width: 1000px) {
  .BatchCheck {
    width: 45%;
  }
}
@media (min-width: 1000px) and (max-width: 1400px) {
  .BatchCheck {
    width: 30%;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .BatchCheck {
    width: 25%;
  }
}

@media (min-width: 2001px) {
  .BatchCheck {
    width: 25%;
  }
}

@media (min-width: 200px) and (max-width: 1000px) {
  .groupCheck {
    width: 90%;
  }
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .groupCheck {
    width: 46%;
  }
}

@media (min-width: 1400px) and (max-width: 2000px) {
  .groupCheck {
    width: 32.5%;
  }
}

@media (min-width: 2001px) {
  .groupCheck {
    width: 32.5%;
  }
}

@media (max-width: 620px) {
  .chatMenu {
    flex-basis: 100%;
    height: 100vh;
    background: #fff;
    padding: 10px;
  }
  .chatBox {
    display: none;
  }
  .parent.backActive {
    width: 100%;
  }

  .parent.backActive .chatMenu {
    display: none;
  }

  .parent.backActive .chatBox {
    display: block;
    width: 100%;
    flex-basis: 100%;
  }

  .BackBtn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .chatBoxTop > main div {
    width: 80%;
  }
}

@media (min-width: 620px) and (max-width: 744px) {
  .chatMenu {
    flex-basis: 40%;
    height: 100vh;
    background: #fff;
    padding: 10px;
  }
  .chatBox {
    flex-basis: 60%;
    height: 100vh;
    /* overflow-y: scroll; */
    position: relative;
  }
  .chatBoxBottom button {
    width: 50px;
    padding: 0;
  }
  .chatBoxTop > main div {
    width: 100%;
  }
}
@media (min-width: 744px) and (max-width: 892px) {
  .chatMenu {
    flex-basis: 30%;
    height: 100vh;
    background: #fff;
    padding: 10px;
  }
  .chatBox {
    flex-basis: 70%;
    height: 100vh;
    /* overflow-y: scroll; */
    position: relative;
  }
  .chatBoxBottom button {
    width: 50px;
    padding: 0;
  }
  .chatBoxTop > main div {
    width: 100%;
  }
}
.textEditStyle {
  line-height: normal;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  word-break: break-word;
}
.textEditStyle > p {
  margin-top: 0px;
}
