.centered {
  position: fixed;
  top: 78%;
  left: 89%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 84px;
  height: 130px;

  background: #fff;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 4%);
}

.modalHeader {
  height: 50px;
  background: #fff;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtnn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: black;

  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 7px;
  top: 2px;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

#filesubmit {
  width: 36px;
  border-radius: 10%;
  height: 36px;
  background: #15803d;
  display: flex;
  justify-content: center;
  align-items: center;
}
#filesubmit:hover {
  background-color: #16a34a;
}

#filesubmit svg {
  color: #fff;
  font-weight: bold;
  font-size: 22px;
}

.fileContainer {
  position: absolute;
  left: 0px;
}

.fileupload {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}
.fileupload input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.sendbtnfile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.loader {
  /* background: #000;
  padding: 50px; */
  /* width: 100%; */
  /* height: 90vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}
.imagePreviewContainer {
  position: relative;
  display: inline-block;
}

.deleteButton {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: rgba(234, 234, 234, 0.831);
  border-radius: 4px;
}

.imagePreviewContainer:hover .deleteButton {
  display: block;
}

.deleteButton svg {
  width: 20px;
  height: 20px;
  fill: crimson;
}
.imagePreview {
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.nonImagePreview {
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.fileIcon {
  font-size: 24px;
  margin-right: 10px;
}

.fileName {
  flex-grow: 1;
}

.deleteIcon {
  cursor: pointer;
  color: crimson;
  font-size: 20px;
  margin-left: 10px;
}
