/* Root Container */
.profileRoot {
  height: 94vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.rootProfileContent {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  max-height: 90vh;
  width: 100%;
  max-width: 700px;
  border-radius: 4px;
  background-color: #fff !;
  box-shadow: 0 0 10px rgba(8, 7, 7, 0.5);
  border: 0.3px solid gray;
  padding: 16px;
  gap: 2%;
}

.rootProfileContent1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  max-height: 90vh;
  width: 100%;
  max-width: 70%;
  border-radius: 4px;
  background-color: #fff !;
  box-shadow: 0 0 10px rgba(8, 7, 7, 0.5);
  border: 0.3px solid gray;
  padding: 16px;
  gap: 2%;
}

/* Left Side Container */
.leftSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-right: 1px solid rgba(128, 128, 128, 0.663);
  padding-right: 10px;
  flex: 0 0 auto;
  width: 50%;
}

.leftSide1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-right: 1px solid rgba(128, 128, 128, 0.663);
  padding-right: 10px;
  flex: 0 0 auto;
  width: 33%;
}

/* Right Side Container */
.rightSide {
  /* width: 100%; */
  min-height: 45vh;
  max-height: 89vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 49%;
}

.headContentDisplay {
  height: 5vh;
}

.detailsContainerBlock {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

/* Avatar Styling */
.avatar {
  margin-bottom: 16px;
}

/* Heading Styling */
.heading {
  color: #fff;
  margin-bottom: 16px;
  width: 100%;
  background-color: #f57c00;
  padding: 3px 4px;
  border-radius: 5px;
  text-align: center;
}

/* Details Styling */
.details {
  color: #757575;
  margin-bottom: 8px;
}

/* Button Styling */
.button {
  margin-top: 16px;
  width: 100%;
}

.educationItem > h5 {
  color: #f57c00;
  font-size: large;
}

.educationItem > h5 > span {
  color: gray;
  text-transform: capitalize;
  font-size: medium;
}

/* Education Item Styling */
.educationItem > .educationItemContent {
  /* display: flex; */
  /* flex-direction: column; */
  margin-bottom: 10px;
  padding-left: 10%;
}

.educationItemContent > div {
  font-weight: bold;
  color: #555;
  font-size: medium;
  margin-bottom: 5px;
}

.educationItemContent > div > span {
  color: gray;
}

.educationItemContent1 {
  margin-bottom: 10px;
  padding-left: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}

.backButtonStudentProfile {
  position: absolute;
  left: 2%;
  top: 2%;
  border: 1px solid #f57c00;
  padding: 7px 10px;
  border-radius: 15px;
  font-size: medium;
  color: #f57c00;
  font-weight: bold;
}

.educationItemContent1 > div {
  font-weight: bold;
  color: #333;
  font-size: medium;
  margin-bottom: 5px;
  width: 47%;
}

.educationItemContent1 > div > span {
  color: rgb(108, 108, 108);
}

/* Education Title Styling */
.educationTitle {
  font-weight: bold;
  margin-bottom: 4px;
}

.mobileEmailView {
  width: 90%;
  gap: 10px;
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  transition: all 0.5s;
}

.titleEmailMobile {
  font-size: small;
}

.NumberBlock1 {
  width: 100%;
  display: flex;
}

.NumberBlock1 > span:last-child {
  display: inline-block;
  width: 90%;
}

.downloadResumeButton {
  text-decoration: none;
  text-transform: uppercase;
  color: #f57c00;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.downloadResumeButton:hover {
  text-decoration: none;
  text-transform: uppercase;
  color: orangered;
}

.iconHolderProfile > span {
  padding: 1px 1px 0px 1px;
  /* border-radius: 6px; */
  border: 1px solid gray;
  display: inline-block;
  cursor: pointer;
}
.imageBlock {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin: 3px;
  border: 10px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .profileRoot {
    height: auto;
    margin-bottom: 20px;
    overflow: auto;
  }

  .rootProfileContent {
    flex-direction: column;
    max-width: 93%;
    max-height: 90vh;
    overflow: auto;
    margin: 0 auto;
    /* height: auto; */
    gap: 30px;
  }

  .leftSide {
    align-items: center;
    padding-right: 0;
    /* No padding on the right in smaller screens */
  }

  .rightSide {
    width: 90%;
    /* Allow the right side to grow freely */
  }
  .imageBlock {
    width: 130px;
    height: 130px;
  }
  .leftSide {
    width: 80%;
  }
}

.userprofile {
  width: 100%;
  min-height: 50vh;
  max-height: 90vh;
}
