#chatBoxSection article {
  width: 100%;
  background-color: #efefef;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
}

.chatBoxTop > main > span {
  color: #4b5563;
  font-size: larger;
  font-weight: bold;
}

.chatMenu {
  flex-basis: 25%;
  flex-basis: 25%;
  height: 92vh;
  background: #fff;
  padding: 10px;
}

.chatBox {
  flex-basis: 75%;
  height: 90vh;
  overflow-y: scroll;
}

.chatMenu h1 {
  font-size: 16px;
  text-transform: capitalize;
  padding: 0px 0;
  color: rgb(103 58 183);
  text-shadow: 0px 0px 0px rgb(103 58 183);
  line-height: 0px;
}

.chatMenu input {
  padding: 7px;
  border: none;
  width: 100%;
  border-radius: 5px;
  margin: 6px 0 10px;
  background: #efefef5c;
  border: 1px solid #efefef63;
  outline: none;
  font-size: 12px;
}

.chatMenuWrapper {
  position: relative;
  height: 72vh;
}

.ConversationBlock {
  overflow-y: scroll;
  height: 100%;
}

.searchIcon svg {
  position: absolute;
  right: 8px;
  top: 11px;
  color: #666;
}

.listUser {
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea8;
  border-radius: 5px;
  margin: 3px 0;
}

.listUser:hover {
  background: #adacaca8;
}

.conversation {
  color: rgb(40 60 73);
  text-transform: capitalize;
  font-size: 11px;
  font-weight: bold;
}

.chatBoxBottom {
  position: fixed;
  bottom: 6px;
  width: 62%;
  right: 47%;
  display: flex;
  background: #fff;
  padding: 10px;
  z-index: 1;
  left: 36.6%;
}

.chatBox {
  position: relative;
}

.noConversationText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 90vh;
}

.noConversation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 75vh;
}

.chatBoxBottom input {
  background: #fff;
  border: 1px solid #efefef;
  padding: 10px;
  width: 99%;
  outline: 1px solid #ccc;
}

.chatBoxBottom button {
  width: auto;
  border: none;
  padding: 10px 30px;
  background: rgb(103 58 183);
  color: #fff;
  display: inline-block;
  border-radius: 0px 5px 6px 0px;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid rgb(103 58 183);
  cursor: pointer;
  outline: none;
}

.message.own {
  align-items: flex-end;
}

.sendermsg {
  width: 49%;
  margin: 3px 7px;
  padding: 0px 5px;
  float: right;
}

.sendermsg > main {
  background-color: #fff;
  padding: 3px 20px;
  border-radius: 12px 12px 0px 12px;
  margin: 0px 30px;
  float: right;
  border-bottom: 1px solid rgb(103 58 183 / 33%);
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  flex-direction: column;
}

.recivermsg {
  width: 49%;
  margin: 0px 15px;
  float: left;
  padding: 0px 20px;
}

.recivermsg > main {
  background-color: rgb(103 58 183);
  padding: 3px 20px;
  border-radius: 12px 12px 12px 0px;
  margin: 4px 30px;
  float: left;
  border-bottom: 1px solid rgb(103 58 183 / 19%);
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 13px;
  flex-direction: column;
}

.chatBoxTop {
  position: relative;
}

.active {
  background-color: #666;
}

.sendermsg time {
  font-size: 10px;
  color: #888;
}

.recivermsg time {
  color: #efefef78;
  font-size: 10px;
}

.sendermsg div:nth-child(2) {
  float: right;
  text-align: right;
}

.recivermsg div:nth-child(2) {
  float: right;
  text-align: right;
}

.chatBoxTop div p {
  word-break: break-word;
}

.chatBoxTop > main {
  background: #fff;
  padding: 7px;
  font-size: 15px;
  text-transform: capitalize;
  color: #111;
  border-top: 1px solid #cccccc1f;
  border-bottom: 1px solid #ccc;
  position: fixed;
  width: 65.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 11;
}

/* position: fixed; */

#UserBlock h2 {
  display: flex;
  gap: 5px;
  font-weight: 300;
}

.faIcon {
  font-size: 15px;
  color: green;
  padding: 3px 2px;
  display: inline-block;
}

#online {
  color: green;
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
}

#offline {
  color: red;
  font-size: 13px;
  font-weight: bold;
  line-height: 10px;
}

.Username {
  font-size: 17px !important;
  font-weight: bold;
  line-height: 6px;
}

.customStylesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffffad;
  width: 40%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UploadfileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.UploadfileContainer span {
  font-size: 120%;
  padding: 10px;
}

.Messageeee:nth-child(1),
.Messageeee:nth-child(1) {
  /* margin-top: 40px; */
}

/* Modal css starts here */

.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 250px;
  height: 170px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.Broadcast {
  padding: 2px 9px;
  background: #3b82f6;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: bold;
}

.Broadcast:hover {
  background-color: #2563eb;
}

.Head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drop {
  flex-basis: 60%;
}

.Emptymsg {
  display: none;
}

.Messageeee {
  padding: 0px;
}

.displaynone {
  display: none;
}

.broadcast {
  font-size: 11px;
  display: flex;
  position: relative;
  color: #666;
}

.broadcast svg {
  position: absolute;
  top: 2px;
  font-size: 10px;
  right: -11px;
}

.recivermsg > main div p a {
  color: #fff;
}

.sendermsg > main > div .Document {
  color: black;
  text-decoration: none;
}

.sendermsg > main > div .Document:hover {
  text-decoration: none;
  color: black;
}

.recivermsg > main div .Document {
  color: white;
  text-decoration: none;
}

.recivermsg > main > div .Document:hover {
  text-decoration: none;
  color: white;
}
.messageTop pre {
  /* font-family: Lato, sans-serif;
  font-size: 14px;
  color: #fff;
  /* background-color: rgba(143, 142, 142, 0.12); */
  /* padding: 5px;  */
  white-space: pre-wrap; /* Ensure long text wraps */
  word-wrap: break-word;
}


.Download {
  color: #3498ff;
  display: block;
  text-decoration: underline;
}

.sendermsg > main > div .Document {
  color: black;
  text-decoration: none;
}

.sendermsg > main > div .Document:hover {
  text-decoration: none;
  color: black;
}

.recivermsg > main div .Document {
  color: white;
  text-decoration: none;
}

.recivermsg > main > div .Document:hover {
  text-decoration: none;
  color: white;
}
.messageTop pre {
  /* font-family: Lato, sans-serif;
  font-size: 14px;
  color: #fff;
  /* background-color: rgba(143, 142, 142, 0.12); */
  /* padding: 5px;  */
  white-space: pre-wrap; /* Ensure long text wraps */
  word-wrap: break-word;
}


.loadmoreBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.loadmoreBlock button {
  width: 72%;
  background: rgb(52 152 255 / 25%);
  color: black;
}

.Messageeee:nth-child(1),
.Messageeee:nth-child(1) {
  padding: 46px 0px 0px 0px;
}
