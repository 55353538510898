#CreateGroup {
  width: 100%;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 20px; */
  /* background: #efefef; */
  /* background: gray; */
}

#CreateGroup h1 {
  font-size: 35px;
}

#CreateGroup main {
  /* width: inherit;
  height: 80%;
  margin: auto; */
  width: inherit;
  height: 91vh;
  margin: auto;
}

#CreateGroup main form {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  gap: 16px;
  /* background: rgb(151, 148, 148); */

  flex-wrap: wrap;
  border-radius: 15px;
}

#CreateGroup main form>div {
  display: flex;
  width: 30%;
  flex-direction: column;
}

#CreateGroup main .form_design {
  background: #fff;
  width: 80%;
  height: auto;
  margin: 50px auto;
  border-radius: 10px;
  border: 0.3px ridge gray;
  box-shadow: 1px 0px 8px #f16f4d;
}

#CreateGroup main .form_design h1 {
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize;
  font-size: 24px;
  text-decoration: underline;
  text-align: center;
}

#CreateGroup main form>div>input {
  padding: 7px;
  border-radius: 5px;
  border: 1px solid hsl(0, 0%, 80%);
}

#CreateGroup main form>div>label {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

#CreateGroup main form>div>input:focus {
  border: none;
  outline: 2px solid #007aff;
}

#CreateGroup main form>aside {
  display: flex;
  flex-direction: column;
  width: 30%;
}

#CreateGroup main form>aside>label {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

#CreateGroup main form>article {
  display: flex;
  width: 94%;
  flex-direction: column;
  background-color: transparent;
}

#CreateGroup main form>article>label {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

#CreateGroup main form>article>div {
  width: auto;
  /* overflow-y: scroll;
  height: 100%; */
}


#CreateGroup main .button_color {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 auto;
  width: 70%;
}

#CreateGroup main .button_color>button {
  padding: 5px 13px;
  background: #f57c00;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#CreateGroup main .button_color>button:hover {
  background: #ff8e1d;
}

.errors {
  color: red;
  font-size: 12px;
  padding: 0px 4px;
  margin-bottom: 15px;
}

.Qrcode {
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Styles.module.css */

/* Styles.module.css */

.StudentListBlock {
  text-align: center;
  margin: 20px;
}

.ListBlock {
  margin-top: 20px;
  background: #efefef;
  padding: 10px;
  gap: 7px;
  flex-wrap: wrap;
  height: 75vh;
  overflow: auto;
}

.HeadingBlock {
  display: flex;
  justify-content: space-between;
}

.UserContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.ListStudents {
  font-size: 1.2rem;
  margin-right: 10px;
}

.UserContainer:hover {
  background-color: #ccc;
}

.UserIcon {
  font-size: 1.5rem;
  color: #007bff;
}

.SearchBlock {
  width: 50%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.SearchInput {
  border: none;
  border-radius: 4px;
  padding: 8px;
  font-size: 1rem;
  width: 70%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.SearchButton {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SearchButton:hover {
  background-color: #0056b3;
}

.SearchButtonred {
  background-color: rgb(226, 22, 22);
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SearchButtonred:hover {
  background-color: red;
}

.StudentListBlock1 {
  display: flex;
  justify-content: space-around;
  background-color: #efefef;
}

.UserContainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
}

.ListBlock1 {
  width: 48%;
}

.BtnUpdate {
  background-color: #16a34a !important;
  color: #fff !important;
}

.StudentName {
  width: 100%;
  /* background: #efefef; */
  border-radius: 5px;
  padding: 7px;
  border: 1px solid #cac5c5;
}

.Active {
  color: #16a34a;
  font-weight: bold;
  background: transparent;
}

.Active:hover {
  background-color: transparent;
  color: #16a34a;
}

.InActive {
  color: darkred;
  font-weight: bold;
  background: transparent;
}

.InActive:hover {
  color: darkred;
  font-weight: bold;
  background: transparent;
}

.CkeckOld {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.LabelForm {
  font-size: 16px;
  font-weight: 900;
}

.HeadingMock {
  color: #e9500e !important;
  border-bottom: 1px solid #e9500e;
  font-size: 16px !important;
}

.formgroup {
  margin-bottom: 10px;
  background: rgb(241, 241, 241);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.error {
  color: red;
}

.updateBlock {
  display: flex;
}

.UpdateMockFormBlock {}

.MainBlockMock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

}

.filterBlock {
  background-color: #2d2d2d;
  margin-top: 0 !important;
  padding-bottom: 5px;
}

.searchIcon {
  position: absolute;
  right: 5px;
  bottom: 2px;
  background-color: transparent;
}

.filterBtn:hover {
  background-color: #fdaa4b;
}

.clearFilterBtn {
  /* background-color: gray; */
  border: none;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
}

.filterBtn {
  background-color: #f8931f;
  /* color: #fff; */
  border: none;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
}

.studentListTable table {
  padding: 0px !important;
  border: none;
  border-collapse: collapse;
}

.studentNameList {
  font-size: small;
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
}

.studentListTable table th {
  background-color: none;
}

.paginationButtons {
  padding: 3px 5px;
  background-color: transparent;
  color: #f8931f;
  font-weight: bold;
  /* border: 0.5px solid green; */
  border-radius: 20px;
  display: flex;
  align-items: center;
}