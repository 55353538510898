.MainBlockDelete {
  width: 100%;
  height: 91vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.myForm {
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  width: 35%;
}

.form-group {
  margin-bottom: 15px;
}

.formgroup > label {
  display: block;
  margin-bottom: 5px;
}
.inputBlock {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 3px;
}

.DeleteButton {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}
