/* Reset some default browser styles */
body,
h1,
h2,
ul,
li {
  margin: 0;
  padding: 0;
}

/* Basic styling for the app */
.app {
  display: flex;
  height: 92vh;
  font-family: Arial, sans-serif;
  width: 100%;
}

/* Sidebar styles */
.sidebar {
  background-color: #fff;
  color: white;
  padding: 10px;
  border: 1px solid rgb(207, 206, 206);
  width: 25%;
  margin-top: 50px;
  height: 92vh;
}

/* Chat area styles */
.chatarea {
  border-left: 1px solid #e0e0e0;
  height: 100%;
  width: 75%;
}

/* Header styles */
.header {
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Contact Info and Actions */
.contactinfo {
  display: flex;
  align-items: center;
}

.contactavatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.contactavatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-name {
  font-weight: bold;
}

/* Chat styles */
.ChatBlock {
  height: 90%;
  display: flex;
}

.helpChatTitleList {
  width: 27%;
  padding: 0.6%;
  border-right: 0.3px ridge lightgray;
  height: 100%;
}

.chat {
  /* max-height: 500px; */
  overflow-y: auto;
  /* margin-bottom: 15px; */

  /* height: 81%; */
}

.message {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.message.me {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message.other {
  background-color: #d9d9d9;
  align-self: flex-start;
}

/* Message input styles */
.messageinput {
  display: flex;
  align-items: center;
  /* height: 10vh; */
  background: #fff;
  height: 8%;
  width: 100%;
}

.messageinput input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

.sendbutton {
  padding: 10px 20px;
  background-color: #f16f1d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.sendbutton:hover {
  background-color: #ff7c2b;
}

/* Styles for the search bar */
.search {
  display: flex;
  align-items: center;
  /* background-color: #f0f0f0; */
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.contacts {
  overflow-y: auto;
  height: 85%;
}

.SearchBlock {
  display: flex;
  align-items: center;
  flex: 1;
}

.SearchText {
  flex: 1;
  border: 1px solid #f16f1d;
  border-radius: 4px;
}

.SearchTextInput {
  padding: 6px;
  border: none;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: none;
}

.SearchTextInput:active,
.SearchTextInput:focus {
  border: none !important;
}

.searchIcon,
.searchCancel {
  background-color: #f16f1d;
  border: none;
  color: white;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
}

.searchIcon {
  margin-left: 5px;
}

/* Adjust icons on hover */
.searchIcon:hover,
.searchCancel:hover {
  background-color: #fb6101;
}

.UserList {
  font-size: 20px;
  color: #128c7e;
}

.UserList:hover {
  color: #0a665d;
}

/* Chat message styles */
.message {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 11px;
  border-radius: 5px;
  margin: 5px 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.message:hover {
  border-left: 1px solid #f16f1d;
}

.senderinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #fb6101;
  margin-bottom: 5px;
  font-weight: bold;
  text-decoration: capitalize;
  width: 100%;
  word-break: break-word;
}

.sendername {
  font-weight: bold;
  margin-right: 5px;
  width: 100%;
  color: rgb(48 64 74);
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
}

.tickicon {
  color: #128c7e;
}

.messagetext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* font-size: 12px; */
  /* color: #888; */
  margin-bottom: 5px;
  font-size: 11px;
  color: #626562;
}

.timestamp {
  /* width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #25d366; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px; */

  background: rgba(7, 192, 7, 0.877);
  border-radius: 100%;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

/* CreateGroup */
.header1 {
  padding: 10px 10px;

  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0a665d;
  color: #fff;
}

.header1 > span {
  color: #fff;
}

.header1 > span:hover {
  /* color: #ccc; */
}

.GroupName {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.GroupName > .EnterGroupName {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
}

.CreateGroupBlock {
  background: #fff;
  padding: 10px;
  border-radius: 12px;
}

.LabelGroup {
  font-size: 18px;
  font-weight: bold;
}

.Count {
  color: #128c7e;
  font-size: 18px;
  margin-left: 20px;
}

/* Css for Message Component */
.NameBlock {
  margin-top: 50px;

  width: 100%;
  /* height: 7vh; */
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  height: 50px;
  border-bottom: 1px solid rgb(192, 192, 192);
  height: 8%;
  border-top: 1px solid rgb(192, 192, 192);
}

/* .chat {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  margin: 8px;
  border-radius: 8px;
  max-width: 70%;
} */
.sendermsg {
  float: right;
  width: 80%;
  margin: 15px 10px;
  padding: 0px 20px;

  /* background-color: #dcf8c6; */
}

.sendermsg > main {
  background-color: #dcf8c6;
  padding: 4px 18px;
  padding-left: 12px;
  border-radius: 0px 16px 16px 16px;
  /* margin: 0px 30px; */
  float: right;
  border-bottom: 1px solid rgb(103 58 183 / 33%);
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  flex-direction: column;
  cursor: pointer;
}

.recivermsg {
  /* float: left; */
  width: 80%;
  margin: 15px 10px;
  float: left;
  padding: 0px 20px;
  /* margin-top: 15px; */

  /* background-color: #f0f0f0; */
}

.recivermsg > main {
  background-color: #fff;
  padding: 4px 18px 4px 12px;
  border-radius: 12px 0px 16px 16px;
  margin: 4px 0px;
  float: left;
  border-bottom: 1px solid rgb(103 58 183 / 19%);
  display: flex;
  justify-content: space-between;
  /* color: #fff; */
  font-size: 14px;
  flex-direction: column;
}

.sendermsg time {
  font-size: 10px;
  color: #888;
}

.recivermsg time {
  color: #888;
  font-size: 10px;
}

.noConversationText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 92vh;
  width: 75%;
  margin-top: 50px;
}

.noConversationText > p {
  font-size: 2.2rem;
  font-size: 2.2rem;
  color: gray;
}

.noConversationText > div {
  color: gray;
  font-size: 1.2rem;
  display: flex;
}

.noConversationText > div > span {
  color: #128c7e;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.noConversation {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  color: gray;
  height: 75vh;
}

.textmsg {
  white-space: pre-wrap;
  /* word-break: break-all; */
  word-break: break-word;
}

.btndownload1 {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.btndownload {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.linkBlock {
  color: #1675e0;
}

.linkBlock:hover {
  text-decoration: underline;
}

.UploadfileContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 20px;
  color: #475569;
}

.UploadfileContainer span {
  font-size: 120%;
  padding: 10px;
}

.AudioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AudioBlock > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AudioBlock > div > main {
  font-size: 18px;
  padding-right: 5px;
}

.StartAudio {
  color: black;
}

.StopAudio {
  color: red;
}

.SendAudioBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PlayAudio {
  color: #16a34a;
}

.DeleteAudio {
  color: red;
  font-size: 22px;
  padding: 0px 8px 0px 4px;
}

.SendAudio {
  padding: 5px 16px;
  border: 2px solid green;
  border-radius: 5px;
  color: #fff;
  background: green;
  font-size: 17px;
  text-transform: capitalize;
}

.AudioNot {
  display: none;
}

.recordingAnimation {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.recordingAnimation.active {
  opacity: 1;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
  }
}

.Online {
  color: rgba(7, 143, 7, 0.877);
}

.Offline {
  color: rgba(198, 15, 15, 0.852);
}

.ListForward {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 5px 11px;
  border-bottom: 1px solid rgb(103 58 183 / 13%);
  background: #eeeeeea9;
  border-radius: 5px;
  margin: 5px 0;
  color: #475569;
  font-weight: 800;
}

.ListForward:hover {
  background-color: #c6c2c2c1;
}

.sidebar1 {
  width: 0;
  height: 91%;
  background-color: #00000052;
  /* Sidebar background color */
  position: absolute;
  top: 0;
  right: 0;
  /* Change left to right */
  transition: width 0.3s ease;
  overflow-x: hidden;
  display: none;
  margin-top: 50px;
}

.open {
  width: 350px;
  /* Adjust the width as needed */
  display: block;
}

.sidebarContent {
  /* padding: 20px; */
  /* color: white; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 8px;
}

.openSidebarButton {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.HearderSidebar {
  display: flex;
  height: 50px;
  justify-content: flex-start;
  align-items: center;
  padding: 13px;
}

.HearderSidebar .ImCrossMark {
  color: #475569;
  margin-right: 20px;
}

.PrfileIcon {
  font-size: 45px;
  color: darkslategray;
}

.EDitPrfile {
  color: #272c36;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.EditIcon {
  color: green;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TextGray {
  font-weight: 500;
  color: #272c36;
}

.ListOfUsers {
  background-color: #fff;
  height: 420px;
  overflow-y: auto;
  margin-bottom: 22px;
}

.Head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 23px;
}

.ListParticipates {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px 0px 10px;
  /* border-bottom: 1px solid #c5c6c7; */
  padding: 7px 7px 0px 7px;
}

.iconAndName .IconProfile {
  font-size: 20px;
  color: #888;
}

.Admin {
  background-color: #0dfe6579;
  color: #16a34a;
  font-size: 12px;
  height: 20px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.IconsEditAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.InputGroup {
  border: none;
  border-bottom: 1px solid green;
  outline: none;
}

.AdminROle {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #c5c6c7;
  margin-right: 15px;
}

.AdminROle > span > svg {
  font-size: 20px;
  font-weight: bold;
}

.DisplayFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.LeaveGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: #fff;
  padding: 12px;
  color: red;
}

.LeaveGroup > aside {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.LeaveGroup > aside:hover {
  background-color: #ccc;
}

.LeaveGroup > aside > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.LeaveGroup > aside > span > svg {
  font-size: 20px;
}

.FormMessage {
  padding: 6px;
  background-color: #fff;
  position: relative;
}

.displayNone {
  display: none;
}

.HEadBlockName {
  display: flex;
  /* justify-content: space-between; */
}

.HeadDeatils > h6 {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.GroupIcon {
  font-size: 20px;
  color: #0a665d;
}

.ropdowncontent {
  position: absolute;
  top: -100px;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 100px;
}

.ropdowncontent > ul {
  text-decoration: none;
}

.FileShare:hover {
  background-color: #efefef;
  border-radius: 5px;
}

.HeaderPoll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid #f16f1d;
}

.HeaderPoll > span {
  margin-right: 20px;
}

.modalcontent {
  background-color: #fefefe;
  /* margin: 15% auto; */
  padding: 20px;
  /* border: 1px solid #888; */
  width: 100%;
  max-width: 600px;
  /* text-align: center;  */
}

.InputQue {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  border: none;
  outline: none;
  border-bottom: 2px solid gray;
}

.InputQue:focus {
  border-bottom: 2px solid #4caf50;
}

.AddOptions {
  background-color: lightgray;
  color: #666;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 100;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubmitPoll {
  background-color: transparent;
  color: #f16f1d;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  font-size: 40px;
}

.OptionBlock {
  display: flex;
}

.ClearOption {
  background: transparent;

  padding-left: 10px;
}

.Allow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.datePicker {
  margin: 10px 0;
}

.Pollhead {
  display: flex;
  justify-content: flex-start;
  color: #fb6101;
  text-transform: capitalize;
  font-weight: bold;
}

.AnswerPoll {
  padding: 5px 8px;
  border-radius: 5px;
  background: #34c3ff;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
}

.AnswerPoll:hover {
  background: #05b0f9;
}

.AnswerView {
  padding: 5px 8px;
  border-radius: 5px;
  background: #34c3ff;
  color: #fff;
  font-weight: bold;
}

.AnswerView:hover {
  background: #05b0f9;
}

.NotySort {
  cursor: pointer;
  font-size: 20px;
  color: #16a34a;
}

.ViewPollBlock {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.MainContainer {
  width: 500px;
}

.options {
  padding: 5px;
  background-color: #efefef;
  border-radius: 5px;
}

.Voters {
  display: flex;
  justify-content: space-between;
}

/* Style for the container holding the checkbox and label */
.polloption {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

/* Style for the checkbox input */
.polloption > input[type="checkbox"] {
  margin-right: 10px;
}

/* Style for the label associated with the checkbox */
.polloption > label {
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  background: #efefef;
  padding: 5px;
  border-radius: 6px;
}

/* Style for the label when hovering */
.polloption > label:hover {
  text-decoration: underline;
}

.TextAlign {
  white-space: pre-wrap;
  word-break: break-word;
}

.HeadName {
  /* margin-right: 200px; */
}

.existingListUl {
  max-height: 70vh;
  overflow: auto;
}

.existingListUl > ul {
  list-style: none;
}

.existingListUl > ul > li {
  padding: 5px;
  margin-bottom: 6px;
  border: 1px solid gray;
  border-radius: 6px;
  font-weight: bold;
  font-size: medium;
  padding-left: 3%;
  cursor: pointer;
  background-image: url("../../assets/bg6.webp");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ff7f2f;
}

.existingListUl > ul > li:last-child {
  margin-bottom: 0;
}

.adminHelpHeader {
  width: 22%;
  display: flex;
  align-items: center;
  height: 100%;
  overflow-x: auto;
}

.adminHelpHeader > p {
  color: #ff7f2f;
  font-weight: bold;
  font-size: large;
  margin-right: 3px;
}

.adminHelpHeader > small {
  color: #111;
  text-transform: capitalize;
  font-size: medium;
}

.replyBlock {
  position: absolute;
  top: -52px;
  width: 100%;
}

.StatusText {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.12);
  display: flex;
}

.StatusText1 {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
}

.copiedText {
  background-color: rgba(58, 56, 56, 0.37);
  padding: 5px;
  border-radius: 5px;
  border-left: solid;
  width: 90%;
}

.CrossMark {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  color: red;
}
