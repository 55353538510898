.existingListUl {
    max-height: 50vh;
    overflow: auto;
}

.existingListUl>ul {
    list-style: none;
}

.existingListUl>ul>li {
    padding: 5px;
    margin-bottom: 6px;
    border: 1px solid gray;
    border-radius: 6px;
    font-weight: bold;
    font-size: medium;
    padding-left: 3%;
    cursor: pointer;
    background-image: url("../../../assets//bg6.webp");
    background-repeat: no-repeat;
    background-size: cover;
    color: #ff7f2f;
}

.existingListUl>ul>li:last-child {
    margin-bottom: 0;
}

.ChatHelpMainBlock {
    height: 100%;
    width: 100%;
    overflow: hidden;
}