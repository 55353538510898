.FooterBlock {
  width: 100%;
  height: 60px;
  box-shadow: 1px 0px 4px 0px #cccccc5e;
  /* margin-top: 50px; */
}
.FooterBlock > article {
  background-color: #fff;
  height: inherit;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.FooterBlock > article div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons {
  font-size: 14px;
  color: rgb(40, 60, 73);
}
.FooterBlock > article div h3 {
  font-size: 14px;
  color: rgba(40, 60, 73, 0.795);
}
.FooterBlock > article div span {
  display: flex;
}
